import { createRoutes } from '@/libs/router/route'
import { HorseDetailResource } from '@/resources/horse_detail/resource'

export const HorseDetailResourceRoutes = createRoutes({
  resource: HorseDetailResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'horse_details',
      children: [
        {
          index: true,
          page: 'horse_details/index',
          query: HorseDetailResource.query.index,
        },
        {
          path: ':id',
          query: HorseDetailResource.query.show,
          children: [
            {
              index: true,
              page: 'horse_details/show',
            },
          ],
        },
      ],
    },
  ],
})

export const HorseDetailRoutes = HorseDetailResourceRoutes

import type { RouteReleaseStatus } from '@/libs/router/route'

import { PathHelper } from '@/libs/paths/path-helper'

export const useRouteStatus = (path?: PathHelper) => {
  if (!path) {
    return {
      developmentStatus: 'in-progress',
      releaseStatus: 'preview',
      shouldShowRoute: true,
    }
  }

  return {
    developmentStatus: path.developmentStatus,
    releaseStatus: path.releaseStatus,
    shouldShowRoute: shouldShowRoute(path.releaseStatus),
  }
}

function shouldShowRoute(status: RouteReleaseStatus): boolean {
  return status === 'released' || status === 'preview' || process.env.NODE_ENV === 'development'
}

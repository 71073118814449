// JsFromRoutes CacheKey 2076df803496aa2804989e782b446d5d
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const stallionProspectsApi = {
  index: defineEndpointPathHelper('get', '/api/stallion_prospects'),
  create: defineEndpointPathHelper('post', '/api/stallion_prospects'),
  new: defineEndpointPathHelper('get', '/api/stallion_prospects/new'),
  edit: defineEndpointPathHelper('get', '/api/stallion_prospects/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/stallion_prospects/:id'),
  update: defineEndpointPathHelper('patch', '/api/stallion_prospects/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/stallion_prospects/:id'),
}

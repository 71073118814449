// JsFromRoutes CacheKey 1ae3d8c0674f2725c6c3378851268aa4
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { EntityModel } from '@/models/entity'

export const entitiesApi = {
  autocomplete: defineEndpoint({
    controllerName: 'entities',
    actionName: 'autocomplete',
    model: EntityModel,
    parts: [],
    httpMethod: 'get',
  }),
  index: defineEndpoint({
    controllerName: 'entities',
    actionName: 'index',
    model: EntityModel,
    parts: ['api', 'entities'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'entities',
    actionName: 'create',
    model: EntityModel,
    parts: ['api', 'entities'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'entities',
    actionName: 'new',
    model: EntityModel,
    parts: ['api', 'entities', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'entities',
    actionName: 'edit',
    model: EntityModel,
    parts: ['api', 'entities', { name: 'id', attributeId: 'Entity:id' }, 'edit'],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'entities',
    actionName: 'show',
    model: EntityModel,
    parts: ['api', 'entities', { name: 'id', attributeId: 'Entity:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'entities',
    actionName: 'update',
    model: EntityModel,
    parts: ['api', 'entities', { name: 'id', attributeId: 'Entity:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'entities',
    actionName: 'destroy',
    model: EntityModel,
    parts: ['api', 'entities', { name: 'id', attributeId: 'Entity:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}

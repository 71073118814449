// JsFromRoutes CacheKey 0d9b847ad849910ec4c1d97b0111ea66
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { BroodmareProspectDetailModel } from '@/models/broodmare_prospect_detail'

export const broodmareProspectDetailsApi = {
  index: defineEndpoint({
    controllerName: 'broodmare_prospect_details',
    actionName: 'index',
    model: BroodmareProspectDetailModel,
    parts: ['api', 'broodmare_prospect_details'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'broodmare_prospect_details',
    actionName: 'show',
    model: BroodmareProspectDetailModel,
    parts: [
      'api',
      'broodmare_prospect_details',
      {
        name: 'broodmareProspectDetailId',
        attributeId: 'BroodmareProspectDetail:broodmareProspectDetailId',
      },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}

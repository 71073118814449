// JsFromRoutes CacheKey 3e96c34e5edd081ffdd5ad0d06184b82
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const shortlistsApi = {
  index: defineEndpointPathHelper('get', '/api/sales/:saleId/shortlists'),
  create: defineEndpointPathHelper('post', '/api/sales/:saleId/shortlists'),
  new: defineEndpointPathHelper('get', '/api/sales/:saleId/shortlists/new'),
  edit: defineEndpointPathHelper('get', '/api/shortlists/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/shortlists/:id'),
  update: defineEndpointPathHelper('patch', '/api/shortlists/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/shortlists/:id'),
}

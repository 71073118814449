import React, { useState, useEffect } from 'react'

import { HorseImage } from '@/components/horse_image'
import { ImagePlus } from '@/components/icon'
import { Link } from '@/components/link'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselApi,
} from '@/components/ui/carousel'
import { Text } from '@/components/ui/text'
import { useResourceQuery } from '@/hooks'
import { useLoaderQueryBuilders } from '@/hooks/use_loader_query_builders'
import { ImageResource } from '@/resources/image'
import { imagesRoutes } from '@/routes'
import { cn, formatDate } from '@/utils'

interface ImageGalleryProps {
  horseId: number
  initialImageId?: number
}

const ImageGallery = ({ horseId, initialImageId }: ImageGalleryProps) => {
  const [api, setApi] = useState<CarouselApi>()
  const [current, setCurrent] = useState(1)
  const [count, setCount] = useState(0)
  const queries = useLoaderQueryBuilders()
  const { record: horse } = useResourceQuery(queries.horsesShow)

  const { records: images } = useResourceQuery(
    ImageResource.query.index
      .filter('horse_id', horse.id)
      .sort('date', 'desc')
      .sort('created_at', 'desc'),
  )

  useEffect(() => {
    if (!api) {
      return
    }

    setCount(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap() + 1)

    api.on('select', () => {
      setCurrent(api.selectedScrollSnap() + 1)
    })

    if (initialImageId) {
      const imageIndex = images.findIndex((image) => image.id === initialImageId)
      if (imageIndex !== -1) {
        api.scrollTo(imageIndex)
      }
    }
  }, [api, initialImageId, images])

  const imageIdToIndex = images.reduce<Record<number, number>>((acc, image, index) => {
    acc[image.id] = index
    return acc
  }, {})

  const scrollToImageById = (id: number) => {
    const index = imageIdToIndex[id]
    if (index !== undefined) {
      api?.scrollTo(index)
    }
  }

  if (!images || images.length === 0) {
    return 'No Images'
  }

  return (
    <Carousel
      setApi={setApi}
      opts={{ loop: true }}
      className="fixed left-1/2 top-1/2 z-50 grid max-h-full w-[80rem] max-w-fit -translate-x-1/2 -translate-y-1/2 bg-transparent p-6 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg phone:w-full phone:max-w-full"
    >
      <div className="flex h-min w-full justify-between">
        <Text as="h1" size="3xl" className="pb-2 font-normal text-white phone:text-xl">
          {horse.name}
        </Text>
        <Text as="h1" size="3xl" className="pb-2 font-normal text-white phone:text-xl">
          {images[current - 1]?.ageMonths} months
          <span className="pl-3">{formatDate(images[current - 1]?.date)}</span>
        </Text>
        <Link
          className="text-white"
          route={imagesRoutes.edit.withOptions({ imageId: images[current - 1]?.id })}
        >
          <Text as="h1" size="3xl" className="pb-2 font-normal phone:text-xl">
            Edit
          </Text>
        </Link>
      </div>
      <CarouselContent>
        {images.map((image) => (
          <CarouselItem key={image.id} className="flex h-min justify-center">
            <HorseImage
              hideTag
              image={image}
              variantName="original"
              behavior="contain"
              className="h-[40rem] min-h-full w-full tablet:max-h-[35rem]"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
      <CarouselPrevious className="bg-secondary/40 text-white phone:hidden" />
      <CarouselNext className="bg-secondary/40 text-white phone:hidden" />
      <div className="flex h-min w-full justify-end pt-2 font-normal text-muted-foreground">
        {current} of {count}
      </div>
      <div className="flex justify-center phone:hidden">
        {images.map((image) => (
          <div
            onClick={() => {
              scrollToImageById(image.id)
            }}
            key={image.id}
            className={cn(
              'h-min w-[72px] cursor-pointer rounded p-1',
              current === imageIdToIndex[image.id] + 1 ? 'ring-1 ring-primary-foreground' : '',
            )}
          >
            <HorseImage
              key={image.id}
              image={image}
              hideTag
              size="container"
              variantName="sm"
              behavior="cover"
              className={cn(
                'h-[54px]',
                current === imageIdToIndex[image.id] + 1 ? '' : 'brightness-75',
              )}
            />
          </div>
        ))}
        <div className="p-1">
          <Link
            variant="primary"
            route={imagesRoutes.new.withOptions({ horseId })}
            size="xl"
            className="h-[54px] w-[72px] rounded p-2"
            iconLeft={<ImagePlus />}
            noChevron
          />
        </div>
      </div>
    </Carousel>
  )
}

export default ImageGallery

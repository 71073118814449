import { DisplayEndpoint, Endpoint, EndpointRequestFn } from '@/libs/endpoint/endpoint'
import { noopEndpoint } from '@/libs/endpoint/noop-endpoint'
import { RouteOptions, mergeRouteOptions } from '@/libs/paths/options'
import { ResourceRecord } from '@/libs/resource/types'
import { queryOptions, type QueryKey, type UseQueryOptions } from '@tanstack/react-query'

import { ListQueryBuilder, createListQueryBuilder } from './list-query'
import { ViewQueryBuilder, createViewQueryBuilder } from './view-query'

type QueryKeyFn = (options?: RouteOptions) => QueryKey

export interface AbstractQueryBuilder<T extends ResourceRecord = ResourceRecord> {
  route: Endpoint<T>
  routeName: string
  queryKeyFn: QueryKeyFn
  baseOptions?: RouteOptions
  build: (options?: RouteOptions, clientSide?: boolean) => UseQueryOptions<T, unknown, T>
}

export type BaseQueryBuilder<T extends ResourceRecord = ResourceRecord> =
  AbstractQueryBuilder<T> & {
    type: 'base'
    route: Endpoint<T>
    withOptions: (options: RouteOptions) => BaseQueryBuilder<T>
  }

const createBaseQueryBuilder = <T extends ResourceRecord>(
  name: string,
  route: Endpoint<T>,
  queryKeyFn: QueryKeyFn,
  queryFn: EndpointRequestFn<T, RouteOptions>,
  baseOptions?: RouteOptions,
): BaseQueryBuilder<T> => {
  const build = (options?: RouteOptions) => {
    const mergedOptions = mergeRouteOptions(baseOptions, options)

    return queryOptions({
      queryKey: queryKeyFn(mergedOptions),
      queryFn: () => queryFn(mergedOptions),
    })
  }

  const withOptions = (addlOptions: RouteOptions) =>
    createBaseQueryBuilder(
      name,
      route,
      queryKeyFn,
      queryFn,
      mergeRouteOptions(baseOptions, addlOptions),
    )

  return {
    type: 'base',
    route,
    routeName: name,
    queryKeyFn,
    withOptions,
    build,
  }
}

// Returns a promise that auto resovles with empty object
const noopQuery = createBaseQueryBuilder(
  'noop',
  noopEndpoint,
  () => ['noop'],
  () =>
    new Promise<Record<string, never>>((resolve) => {
      resolve({})
    }),
)

export type QueryBuilder<T extends ResourceRecord> =
  | BaseQueryBuilder<T>
  | ViewQueryBuilder<T>
  | ListQueryBuilder<T>

const createQueryBuilder = <T extends ResourceRecord>(route: DisplayEndpoint<T>) => {
  return route.type === 'list' ? createListQueryBuilder<T>(route) : createViewQueryBuilder<T>(route)
}

export { createQueryBuilder, noopQuery }

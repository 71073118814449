import { AddressBook, Contract, Notebook, Target, UserDollar, UsersGroup } from '@/components/icons'
import { booksRoutes } from '@/routes'
import { defaultMatingYear } from '@/utils'

import { PanelConfig } from '../panel-types'

export const seasonsPanelConfig = (params): PanelConfig => {
  const bookYear = params.bookYear ?? defaultMatingYear()
  return [
    {
      label: 'Seasons',
      items: [
        {
          label: 'Books',
          route: booksRoutes.show.withOptions({ bookYear }),
          icon: <Notebook />,
        },
        {
          label: 'Seasons',
          route: booksRoutes.seasons.withOptions({ bookYear }),
          icon: <Contract />,
        },
        {
          label: 'Targets',
          route: booksRoutes.targets.withOptions({ bookYear }),
          icon: <Target />,
        },
      ],
    },
    {
      label: 'Other',
      items: [
        {
          label: 'Clients',
          icon: <AddressBook />,
        },
        {
          label: 'Agents',
          icon: <UserDollar />,
        },
        {
          label: 'Syndicates',
          icon: <UsersGroup />,
        },
      ],
    },
  ]
}

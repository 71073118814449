import React from 'react'

import { Action } from '@/components/actions'
import { NewAction } from '@/components/resource'
import { useResource } from '@/hooks'
import { horsesRoutes } from '@/routes'
import { Horse } from '@/types'

import { HorseFormProps } from './form'
import { HorseResource } from './resource'
import { thoromanagerLink } from './utils'

export const NewHorseAction = (props: HorseFormProps) => (
  <NewAction resource={HorseResource} {...props} />
)

export const FiveCrossAction = ({ horseId, ...props }: { horseId: number }) => {
  return (
    <Action
      name="fiveCross"
      label="5-Cross"
      route={horsesRoutes.show.fiveCross.withOptions({ horseId })}
      {...props}
    />
  )
}

export const HypotheticalFiveCrossAction = ({
  damId,
  sireId,
  ...props
}: {
  damId: number
  sireId: number
}) => {
  return (
    <Action
      route={horsesRoutes.show.fiveCross.withOptions({ damId, sireId })}
      name="fiveCross"
      label="5-Cross"
      {...props}
    />
  )
}

export const PedigreeAction = ({ horseId, ...props }: { horseId: number }) => {
  return (
    <Action
      route={horsesRoutes.show.pedigree.withOptions({ horseId })}
      name="pedigree"
      label="Pedigree"
      {...props}
    />
  )
}

export const ThoromanagerActionWithHorse = (props: { horse: Horse }) => {
  const { name, yob, damName, damYob } = props.horse
  return (
    <Action
      name="thoromanager"
      label="Thoromanager"
      to={thoromanagerLink({ name, yob, damName, damYob })}
      target="_blank"
      rel="noreferrer"
      noRouter
      {...props}
    />
  )
}

export const ThoromanagerAction = ({ horseId, ...props }: { horseId: number }) => {
  const resource = useResource<Horse>({ resource: HorseResource, id: horseId })

  const { record: horse } = resource.useRecord()

  return <ThoromanagerActionWithHorse horse={horse} {...props} />
}

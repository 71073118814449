// JsFromRoutes CacheKey 37054d9561abdffdb464fb32099fcb40
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const stallionsApi = {
  racehorses: defineEndpointPathHelper('get', '/api/stallions/:stallionId/racehorses'),
  index: defineEndpointPathHelper('get', '/api/stallions'),
  show: defineEndpointPathHelper('get', '/api/stallions/:id'),
}

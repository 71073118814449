import { RoutePart } from '@/libs/paths/types'
import { QueryBuilder } from '@/libs/query/builder'
import { ResourceRecord } from '@/libs/resource/record'
import { ContextFrame, buildFrame } from '@/libs/stack'
import { compact } from '@/utils'
import QueryString from 'qs'

export const augmentQuery = (query: QueryBuilder, frames: ContextFrame[], search: string) => {
  const model = query.route.model

  // If it's a list query, add query filters for attributes
  if (query.type === 'list')
    return frames.reduce((q, frame) => {
      const attr = model.getAttributeById(frame.attributeId)

      if (!attr) return q

      const name = attr.kind === 'association' ? attr.foreignKey : attr.name
      return q.filter(name, frame.value)
    }, query)

  // If it's a new query, build initial values
  if (query.route.actionName === 'new') {
    const searchString = search.startsWith('?') ? search.slice(1) : search
    const searchInitialValues = QueryString.parse(searchString).initialValues ?? {}

    const stackInitialValues = frames.reduce((values, frame) => {
      const attr = model.getAttributeById(frame.attributeId)
      if (!attr) return values
      const name = attr?.kind === 'association' ? attr.foreignKey : attr.name

      return { ...values, [name]: frame.value }
    }, {})

    return query.withOptions({
      query: {
        [model.recordNameSingular]: {
          ...stackInitialValues,
          ...searchInitialValues,
        },
      },
    })
  }

  return query
}

export const buildLoaderQuery = <T extends ResourceRecord>(
  query: QueryBuilder<T>,
  routeParts: RoutePart[] = [],
  params: Record<string, string>,
  search: string,
) => {
  const frames = compact(
    routeParts.map((routePart: RoutePart) => {
      if (typeof routePart !== 'object') return

      const { attributeId, name } = routePart

      if (attributeId == null || name == null || params[name] == null) return

      return buildFrame({ attributeId, value: params[name] })
    }),
  ) as ContextFrame[]

  const model = query.route.model

  const updatedQuery = augmentQuery(query, frames, search)

  const current = frames.shift()

  // Commenting out the URL extract for now until we know if we should
  // be injecting the searchParams for the api query

  // Access query parameters
  // const url = new URL(request.url)

  // TODO: This isn't perfect and results in all values being strings
  // I believe this will affect our query key matching. Having string values
  // affects our ability to invalidate using custom logic
  // const search = Object.fromEntries(url.searchParams)
  return current
    ? updatedQuery.withOptions({ stack: { current, frames } })
    : updatedQuery.withOptions({ pathParams: params })
}

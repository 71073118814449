// JsFromRoutes CacheKey b86e48ed2f0de97e929dfaea28331f59
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const usersPasswordsApi = {
  new: defineEndpointPathHelper('get', '/api/users/password/new'),
  edit: defineEndpointPathHelper('get', '/api/users/password/edit'),
  update: defineEndpointPathHelper('patch', '/api/users/password'),
  create: defineEndpointPathHelper('post', '/api/users/password'),
}

import { ListEndpoint, Endpoint } from '@/libs/endpoint/endpoint'
import {
  QueryDefBuilder,
  QueryDefBuilderFnsOnly,
  createQueryDefBuilder,
} from '@/libs/filter/builder'
import { RouteOptions, mergeRouteOptions } from '@/libs/paths/options'
import { Model, ResourceRecord } from '@/libs/resource/types'
import { compact } from '@/utils'
import { queryOptions } from '@tanstack/react-query'

import { AbstractQueryBuilder } from './builder'

const modelListQueryKeyFilter = <T extends ResourceRecord>(model: Model<T>, clientSide?: boolean) =>
  compact([model.recordNamePlural, clientSide ? 'clientSide' : null])

const createListQueryKeyFn =
  <T extends ResourceRecord>(route: Endpoint<T>) =>
  (options?: RouteOptions, clientSide = false) => {
    const { pathParams, queryParams, ...otherParams } = route.urlParams(options)

    return [
      ...modelListQueryKeyFilter(route.model, clientSide),
      route.name,
      pathParams,
      queryParams,
      otherParams,
    ]
  }

export type ListQueryBuilder<T extends ResourceRecord> = AbstractQueryBuilder<T> & {
  type: 'list'
  queryDefBuilder: QueryDefBuilder
  route: ListEndpoint<T>
  withOptions: (options: RouteOptions) => ListQueryBuilder<T>

  filter: (...args: Parameters<QueryDefBuilder['filter']>) => ListQueryBuilder<T>
  scope: (...args: Parameters<QueryDefBuilder['scope']>) => ListQueryBuilder<T>
  sort: (...args: Parameters<QueryDefBuilder['sort']>) => ListQueryBuilder<T>
  page: (...args: Parameters<QueryDefBuilder['page']>) => ListQueryBuilder<T>
  pageSize: (...args: Parameters<QueryDefBuilder['pageSize']>) => ListQueryBuilder<T>
  setFilters: (...args: Parameters<QueryDefBuilder['setFilters']>) => ListQueryBuilder<T>
  setSorts: (...args: Parameters<QueryDefBuilder['setSorts']>) => ListQueryBuilder<T>
  setPagination: (...args: Parameters<QueryDefBuilder['setPagination']>) => ListQueryBuilder<T>
}

const createListQueryBuilder = <T extends ResourceRecord>(
  route: ListEndpoint<T>,
  baseOptions: RouteOptions = {},
): ListQueryBuilder<T> => {
  const wrapQueryDefFn =
    (fn: QueryDefBuilderFnsOnly[keyof QueryDefBuilderFnsOnly]) =>
    (...args: Parameters<typeof fn>) =>
      createListQueryBuilder(route, mergeRouteOptions(baseOptions, { q: fn(...args).query }))

  const queryKeyFn = createListQueryKeyFn(route)

  const build = (options?: RouteOptions, clientSide?: boolean) => {
    const mergedOptions = mergeRouteOptions(baseOptions, options)

    return queryOptions({
      queryKey: queryKeyFn(mergedOptions, clientSide),
      queryFn: ({ pageParam }: QueryFunctionContext) =>
        route.request(
          mergeRouteOptions(mergedOptions, {
            q: { pagination: { page: pageParam } },
          }),
        ),
    })
  }

  const withOptions = (addlOptions: RouteOptions) =>
    createListQueryBuilder(route, mergeRouteOptions(baseOptions, addlOptions))

  const queryDefBuilder = createQueryDefBuilder(baseOptions.q)

  const filter = wrapQueryDefFn(queryDefBuilder.filter)
  const scope = wrapQueryDefFn(queryDefBuilder.scope)
  const sort = wrapQueryDefFn(queryDefBuilder.sort)
  const page = wrapQueryDefFn(queryDefBuilder.page)
  const pageSize = wrapQueryDefFn(queryDefBuilder.pageSize)
  const setFilters = wrapQueryDefFn(queryDefBuilder.setFilters)
  const setSorts = wrapQueryDefFn(queryDefBuilder.setSorts)
  const setPagination = wrapQueryDefFn(queryDefBuilder.setPagination)

  return {
    type: 'list',
    routeName: route.name,
    route,
    queryKeyFn,
    queryDefBuilder,

    withOptions,
    build,

    filter,
    scope,
    sort,
    page,
    pageSize,
    setFilters,
    setSorts,
    setPagination,
  }
}

export { createListQueryKeyFn, createListQueryBuilder, modelListQueryKeyFilter }

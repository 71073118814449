// JsFromRoutes CacheKey 7620725975e8224e69f4a106834d6a80
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { HfmWinstarClientModel } from '@/models/hfm_winstar/client'

export const hfmWinstarClientsApi = {
  autocomplete: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'autocomplete',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'get',
  }),
  index: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'index',
    model: HfmWinstarClientModel,
    parts: ['api', 'hfm_winstar', 'clients'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'create',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'post',
  }),
  new: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'new',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'get',
  }),
  edit: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'edit',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'get',
  }),
  show: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'show',
    model: HfmWinstarClientModel,
    parts: [
      'api',
      'hfm_winstar',
      'clients',
      { name: 'clientId', attributeId: 'HfmWinstarClient:clientId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'update',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'patch',
  }),
  destroy: defineEndpoint({
    controllerName: 'hfm_winstar/clients',
    actionName: 'destroy',
    model: HfmWinstarClientModel,
    parts: [],
    httpMethod: 'delete',
  }),
}

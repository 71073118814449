import { Endpoint, ViewEndpoint } from '@/libs/endpoint/endpoint'
import { BaseRouteOptions, mergeRouteOptions } from '@/libs/paths/options'
import { PathParams } from '@/libs/paths/params'
import { ResourceRecord, PrimaryKeyValue, Model } from '@/libs/resource/types'
import { compact } from '@/utils'
import { queryOptions, type UseQueryOptions } from '@tanstack/react-query'

import { AbstractQueryBuilder } from './builder'

const modelViewQueryKeyFilter = <T extends ResourceRecord>(
  model: Model<T>,
  record?: ResourceRecord,
  clientSide?: boolean,
) =>
  compact([
    model.recordNameSingular,
    String(model.getRecordId(record)),
    clientSide ? 'clientSide' : null,
  ])

const createViewQueryKeyFn =
  <T extends ResourceRecord>(route: Endpoint<T>) =>
  (options?: BaseRouteOptions, clientSide?: boolean) => {
    const { pathParams, queryParams, ...otherParams } = route.urlParams(options)

    const baseKey = modelViewQueryKeyFilter(route.model, pathParams, clientSide)

    if (route.actionName === 'show') {
      return baseKey
    }

    return [...baseKey, route.name, pathParams, queryParams, otherParams]
  }

export type ViewQueryBuilder<T extends ResourceRecord> = Omit<AbstractQueryBuilder<T>, 'build'> & {
  type: 'view'
  route: ViewEndpoint<T>
  withOptions: (options: BaseRouteOptions) => ViewQueryBuilder<T>
  build: (opts?: PathParams | PrimaryKeyValue) => UseQueryOptions<T, unknown, T>
}

const createRouteOptions = <T extends ResourceRecord>(
  route: ViewEndpoint<T>,
  opts?: PathParams | PrimaryKeyValue,
) => {
  if (typeof opts === 'string' || typeof opts === 'number') {
    return { pathParams: { [route.model.primaryKey]: opts } }
  }

  if (typeof opts === 'object') {
    return { pathParams: opts }
  }
}

const createViewQueryBuilder = <T extends ResourceRecord>(
  route: ViewEndpoint<T>,
  baseOptions: BaseRouteOptions = {},
): ViewQueryBuilder<T> => {
  const queryKeyFn = createViewQueryKeyFn(route)

  const build = (opts?: PathParams | PrimaryKeyValue, clientSide?: boolean) => {
    const mergedOptions = mergeRouteOptions(baseOptions, createRouteOptions(route, opts))

    return queryOptions({
      queryKey: queryKeyFn(mergedOptions, clientSide),
      queryFn: () => route.request(mergedOptions),
    })
  }

  const withOptions = (addlOptions: BaseRouteOptions) =>
    createViewQueryBuilder(route, mergeRouteOptions(baseOptions, addlOptions))

  return {
    type: 'view',
    route,
    routeName: route.name,
    baseOptions,
    queryKeyFn,
    withOptions,
    build,
  }
}

export { createViewQueryBuilder, createViewQueryKeyFn, modelViewQueryKeyFilter }

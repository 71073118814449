// JsFromRoutes CacheKey f9151873eccedc3f5fd87ca4c084ae4e
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const hairSamplesApi = {
  index: defineEndpointPathHelper('get', '/api/hair_samples'),
  create: defineEndpointPathHelper('post', '/api/hair_samples'),
  new: defineEndpointPathHelper('get', '/api/hair_samples/new'),
  edit: defineEndpointPathHelper('get', '/api/hair_samples/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/hair_samples/:id'),
  update: defineEndpointPathHelper('patch', '/api/hair_samples/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/hair_samples/:id'),
}

// JsFromRoutes CacheKey 3e871bf5c3ea6130fb5d6fddf38a1d7a
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { DBTHorseModel } from '@/models/dbt/horse'

export const dbtHorsesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/horses',
    actionName: 'index',
    model: DBTHorseModel,
    parts: ['api', 'dbt', 'horses'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/horses',
    actionName: 'show',
    model: DBTHorseModel,
    parts: ['api', 'dbt', 'horses', { name: 'id', attributeId: null }],
    httpMethod: 'get',
    type: 'view',
  }),
}

// JsFromRoutes CacheKey 5b08cb79b1bc93804ecc92428bbce413
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const usersRegistrationsApi = {
  cancel: defineEndpointPathHelper('get', '/api/users/cancel'),
  new: defineEndpointPathHelper('get', '/api/users/sign_up'),
  edit: defineEndpointPathHelper('get', '/api/users/edit'),
  update: defineEndpointPathHelper('patch', '/api/users'),
  destroy: defineEndpointPathHelper('delete', '/api/users'),
  create: defineEndpointPathHelper('post', '/api/users'),
}

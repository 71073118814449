// JsFromRoutes CacheKey 05d46a9c9b0aaa7818fe90cee5e56bfa
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { DBTSaleResultModel } from '@/models/dbt/sale_result'

export const dbtSaleResultsApi = {
  index: defineEndpoint({
    controllerName: 'dbt/sale_results',
    actionName: 'index',
    model: DBTSaleResultModel,
    parts: ['api', 'dbt', 'sale_results'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/sale_results',
    actionName: 'show',
    model: DBTSaleResultModel,
    parts: [
      'api',
      'dbt',
      'sale_results',
      { name: 'saleResultId', attributeId: 'DBTSaleResult:saleResultId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}

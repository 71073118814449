import { PanelConfig } from '../panel-types'
import { seasonsPanelConfig } from './seasons'
import { stallionsPanelConfig } from './stallions'

export const homePanelConfig = (params, data): PanelConfig => {
  const seasonsItems = seasonsPanelConfig(params, data)[0].items.slice(0, 2)
  const stallionsItems = stallionsPanelConfig(params, data)[0].items.slice(0, 3)

  return [
    {
      label: 'Crops',
      items: [],
    },
    {
      label: 'Racehorses',
      items: [],
    },
    {
      label: 'Broodmares',
      items: [],
    },
    {
      label: 'Stallions',
      items: [...stallionsItems, ...seasonsItems],
    },
    {
      label: 'Sales',
      items: [],
    },
  ]
}

import {
  PathHelperOptions,
  RouteOptions,
  buildPathHelperOptions,
  mergeRouteOptions,
} from '@/libs/paths/options'
import { PathParams, UrlParams, pathTemplateFromParts } from '@/libs/paths/params'
import { RoutePart, RouteType } from '@/libs/paths/types'
import { Model } from '@/libs/resource/model'
import { ResourceRecord } from '@/libs/resource/record'
import { PartialBy } from '@/utils/types'
import { Method } from '@js-from-routes/client'

import { defineEndpointPathHelper } from './endpoint-path-helper'

export interface BaseEndpointProperties<T extends ResourceRecord = ResourceRecord> {
  type: RouteType
  name: string
  actionName: string
  controllerName: string
  model: Model<T>
  parts: RoutePart[]
  httpMethod: Method
  baseOptions?: RouteOptions
}

export type EndpointRequestFn<T, O> = (options?: O) => Promise<T>
export type PathFn<O> = (options?: O) => string
export type UrlParamsFn<O> = (options?: O) => UrlParams

interface BaseEndpointBehavior<T extends ResourceRecord, P extends RouteOptions = RouteOptions> {
  request: EndpointRequestFn<T, RouteOptions>
  path: PathFn<P>
  canBuildPath: (options?: P) => boolean
  urlParams: UrlParamsFn<RouteOptions>
  buildOptions: (options?: P) => PathHelperOptions
}

type BaseEndpoint<
  T extends ResourceRecord = ResourceRecord,
  P extends RouteOptions = RouteOptions,
> = BaseEndpointProperties<T> & BaseEndpointBehavior<T, P>

export type ListEndpoint<T extends ResourceRecord> = BaseEndpoint<T> & {
  type: 'list'
  withOptions: (options: RouteOptions) => ListEndpoint<T>
}

export type ViewEndpoint<T extends ResourceRecord> = BaseEndpoint<T, PathParams> & {
  type: 'view'
  withOptions: (options: RouteOptions) => ViewEndpoint<T>
}

export type CreateEndpoint<T extends ResourceRecord> = BaseEndpoint<T> & {
  type: 'create'
  withOptions: (options: RouteOptions) => CreateEndpoint<T>
}

export type UpdateEndpoint<T extends ResourceRecord> = BaseEndpoint<T> & {
  type: 'update'
  withOptions: (options: RouteOptions) => UpdateEndpoint<T>
}

export type DestroyEndpoint<T extends ResourceRecord> = BaseEndpoint<T> & {
  type: 'destroy'
  withOptions: (options: RouteOptions) => DestroyEndpoint<T>
}

export type DisplayEndpoint<T extends ResourceRecord> = ListEndpoint<T> | ViewEndpoint<T>

export type MutateEndpoint<T extends ResourceRecord> =
  | CreateEndpoint<T>
  | UpdateEndpoint<T>
  | DestroyEndpoint<T>

export type Endpoint<T extends ResourceRecord> =
  | ListEndpoint<T>
  | ViewEndpoint<T>
  | CreateEndpoint<T>
  | UpdateEndpoint<T>
  | DestroyEndpoint<T>

const defaultEndpointType = <T extends ResourceRecord>(
  model: Model<T>,
  name?: string,
  httpMethod?: Method,
  parts: RoutePart[] = [],
): RouteType | undefined => {
  const commonDefaultEndpointTypes: Record<string, RouteType> = {
    index: 'list',
    autocomplete: 'list',
    table: 'list',
    datatable: 'list',
    rows: 'list',

    show: 'view',
    edit: 'view',
    new: 'view',

    create: 'create',
    update: 'update',
    destroy: 'destroy',
  }

  if (name !== undefined && Object.keys(commonDefaultEndpointTypes).includes(name)) {
    return commonDefaultEndpointTypes[name]
  }

  if (httpMethod === undefined) {
    return
  }

  const primaryKey = model.getAttribute(model.primaryKey)
  const isMember =
    parts.find((part) => typeof part === 'object' && part.attributeId === primaryKey?.globalId) !==
    undefined

  switch (httpMethod.toUpperCase()) {
    case 'DELETE':
      return 'destroy'
    case 'POST':
      return 'create'
    case 'PATCH':
    case 'PUT':
      return 'update'
    case 'GET':
      return isMember ? 'view' : 'list'
    default:
  }
}

export type DefineEndpointProps<T extends ResourceRecord, R extends Endpoint<T>> = Omit<
  PartialBy<R, 'name'>,
  'request' | 'path' | 'urlParams'
>

const defineEndpoint = <T extends ResourceRecord, R extends Endpoint<T>>(
  props: Pick<R, 'type'> & Omit<BaseEndpointProperties<T>, 'type'>,
) => {
  const {
    actionName,
    controllerName,
    name = `${controllerName}#${actionName}`,
    type = defaultEndpointType(props.model, actionName, props.httpMethod, props.parts),
    model,
    parts,
    httpMethod,
    baseOptions,
  } = props

  if (type === undefined) {
    throw new Error(`Failed to create route '${name}' as type is undefined and cannot be inferred`)
  }

  const template = pathTemplateFromParts(parts, true)
  const pathHelper = defineEndpointPathHelper(httpMethod, template)

  const buildOptions = (options?: RouteOptions) =>
    buildPathHelperOptions({
      model,
      parts,
      options: mergeRouteOptions(baseOptions, options),
    })

  // TODO: Raise error/warning if parameter not resolved
  const request = (options?: RouteOptions) => {
    const { queryParams, pathParams, data, requestOptions } = buildOptions(options)

    return pathHelper({
      ...requestOptions,
      params: {
        query: queryParams,
        ...pathParams,
      },
      data,
    })
  }

  const urlParams = (options?: RouteOptions) => {
    const { queryParams, pathParams } = buildOptions(options)

    return { queryParams, pathParams }
  }

  const path = (params?: PathParams) => {
    const { queryParams, pathParams } = buildOptions({ pathParams: params })

    return pathHelper.path({ query: queryParams, ...pathParams })
  }

  /**
   * Checks if the route has all required
   * path parameters
   */
  const canBuildPath = (params?: PathParams): boolean => {
    try {
      path(params)
      return true
    } catch (e) {
      console.error('Error in canBuildPath')
      console.error(e)
      return false
    }
  }

  const withOptions = (newOptions: RouteOptions) =>
    defineEndpoint<T, R>({
      ...props,
      baseOptions: mergeRouteOptions(baseOptions, newOptions),
    })

  return {
    name,
    actionName,
    controllerName,
    type,
    model,
    baseOptions,
    request,
    path,
    canBuildPath,
    urlParams,
    withOptions,
    buildOptions,
  }
}

export { defineEndpoint }

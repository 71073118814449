import { Actions, Action } from '@/components/actions'
import { defineTable } from '@/components/data-table/builder'
import { TableBuilderSpec } from '@/components/data-table/builder/types'
import {
  booleanFormatter,
  currencyFormatter,
  dateFormatter,
  iconFormatter,
} from '@/components/data-table/formatters'
import { horseFormatter } from '@/components/data-table/formatters/horse_formatter'
import { dbtHfmClientDetailTable } from '@/resources/dbt/hfm_client_detail/data-table'
import { dbtHorseTable } from '@/resources/dbt/horse'
import { dbtReproductionStatusTable } from '@/resources/dbt/reproduction_status/data-table'
import { studFeeTable } from '@/resources/stud_fee/data-table'
import { UserAvatar } from '@/resources/user/user_avatar'
import { booksRoutes } from '@/routes'
import { DBTSeasonDetail } from '@/types'

import { dbtMatingEsnTable } from '../mating_esn/data-table'
import { DBTSeasonDetailResource } from './resource'

export const dbtSeasonDetailTable = defineTable(
  DBTSeasonDetailResource,
  {
    stallion: dbtHorseTable,
    mare: dbtHorseTable,
    priorYearCoveringStallion: dbtHorseTable,
    priorYearStudFee: studFeeTable,
    priorYearReproductionStatus: dbtReproductionStatusTable,
    reproductionStatus: dbtReproductionStatusTable,
    matingEsn: dbtMatingEsnTable,
    agentClientDetail: dbtHfmClientDetailTable,
    ownerClientDetail: dbtHfmClientDetailTable,
  },
  (tbl, props): TableBuilderSpec<DBTSeasonDetail> => {
    const { year, isSales, isSalesAdmin, isCoalition, isSeasons } = props

    return {
      searchColumn: tbl.col.mare.name.id(),
      batchSize: 300,
      initialSorting: isSalesAdmin ? [{ id: 'contractDate', desc: false }] : [],
      initialPinnedColumns: {
        left: [
          tbl.col.stallion.nameYob.id(),
          tbl.col.target.id(),
          tbl.col.mare.nameOverSireWithDam.id(),
          tbl.col.id.id(),
        ],
      },
      columns: [
        tbl.col.year({
          enableHiding: false,
          enableColumnFilter: false,
          filter: { key: 'value' },
        }),

        tbl.col.stallion.name({
          label: 'Stallion',
          filter: { key: 'multiSelect', initialChip: true },
          enableHiding: false,
          isVisible: false,
          enableColumnFilter: true,
        }),
        tbl.col.stallion.nameYob({
          label: 'Stallion',
          size: 200,
          cell: ({ getValue, row }) => {
            return horseFormatter({
              id: row.getValue('horseId'),
              name: getValue(),
              actions: false,
            })
          },
        }),

        tbl.col.target({
          showHeader: false,
          isVisible: isSeasons,
          enableColumnFilter: isSeasons,
          size: 40,
          cell: booleanFormatter({ icon: 'CheckCircle' }),
          filter: { key: 'boolean' },
        }),

        tbl.col.mare.name({
          label: 'Mare',
          enableHiding: false,
          isVisible: false,
          enableColumnFilter: true,
        }),
        tbl.col.mare.nameAgeSex({
          label: 'Mare',
          enableHiding: false,
          isVisible: false,
        }),
        tbl.col.mare.nameOverSireWithDam({ size: 250 }),
        tbl.col.id({
          enableColumnFilter: false,
          size: 100,
          header: () => null,
          cell: ({ row }) => {
            return (
              <Actions numToShow={1}>
                <Action
                  name="edit"
                  to={booksRoutes.seasons.edit.withOptions({
                    relative: false,
                    bookYear: year,
                    seasonId: row.original.id,
                  })()}
                  contextual={false}
                />
                {/*<HypotheticalFiveCrossAction
                  name="5-cross"
                  label="5-Cross"
                  damId={row.original.mareId}
                  sireId={row.original.stallionId}
                />*/}
                {/*<Action
                  name="show"
                  disabled
                  iconLeft={<Icon name="Eye" />}
                  to={booksRoutes.seasons.show.withOptions({
                    relative: false,
                    bookYear: year,
                    seasonId: row.original.id,
                  })()}
                  contextual={ false }
                />*/}
                {/*<Action
                  name="destroy"
                  disabled
                  route={booksRoutes.seasons.destroy.withOptions({
                    seasonId: row.original.id,
                  })}
                />*/}
              </Actions>
            )
          },
        }),

        isSales || isCoalition ? tbl.col.matingEsn.esn() : null,

        tbl.col.priorYearCoveringStallion.nameYob({
          label: `${String(year - 1)} Stallion`,
          enableHiding: false,
        }),

        tbl.col.priorYearStudFee.price({
          enableHiding: false,
          label: 'Stud Fee',
          cell: (props) => currencyFormatter({ nonExtended: true })(props),
        }),

        tbl.compoundCol('stallionOverStudFee', [
          tbl.col.priorYearCoveringStallion.nameYob.id(),
          tbl.col.priorYearStudFee.price.id(),
        ]),

        tbl.col.priorYearReproductionStatus.coverDate({
          enableHiding: false,
          label: `${String(year - 1)} LDB`,
        }),
        tbl.col.priorYearReproductionStatus.coverDateOverStatus(),

        tbl.col.ownerClientDetail.nameWithId({
          label: 'Owner',
          filter: { key: 'startsWith', initialChip: true },
          enableHiding: false,
          enableColumnFilter: true,
        }),
        tbl.col.ownerClientDetail.nameContact({ label: 'Owner' }),

        tbl.col.agentClientDetail.nameWithId({
          label: 'Agent',
          filter: { key: 'startsWith', initialChip: true },
          enableHiding: false,
          enableColumnFilter: true,
        }),
        tbl.col.agentClientDetail.nameContact({ label: 'Agent' }),

        tbl.col.amount({
          cell: currencyFormatter(),
          enableColumnFilter: false,
        }),

        tbl.col.assignedUserName({
          label: 'Assigned',
          filter: { key: 'multiSelect', initialChip: true },
          cell: ({ getValue }) => <UserAvatar name={getValue()} />,
        }),

        tbl.col.seasonType({
          label: 'Type',
          filter: { key: 'multiSelect', initialChip: true },
        }),
        tbl.col.targetStatus({
          isVisible: !isSeasons,
          enableColumnFilter: !isSeasons,
        }),
        tbl.col.contractStatus({
          enableHiding: false,
          filter: { key: 'multiSelect', initialChip: true },
        }),
        tbl.col.contractDate({
          enableHiding: false,
          cell: dateFormatter({ format: "MMM do ''yy" }),
        }),
        tbl.compoundCol('contract', [tbl.col.contractStatus.id(), tbl.col.contractDate.id()]),

        tbl.col.comment({
          enableColumnFilter: false,
          cell: iconFormatter({
            iconKey: 'Clipboard',
            tooltipAccessor: tbl.col.comment.id(),
          }),
        }),

        tbl.col.contractDeliveryInstructions({
          label: 'Instructions',
          enableColumnFilter: false,
          cell: iconFormatter({
            iconKey: 'Clipboard',
            tooltipAccessor: tbl.col.contractDeliveryInstructions.id(),
          }),
        }),

        tbl.col.reproductionStatus.coverDate({
          enableHiding: false,
          label: `${String(year)} LDB`,
        }),
        tbl.col.reproductionStatus.coverDateOverStatus(),
      ],
    }
  },
)

import { generatePath, type Path } from 'react-router-dom'

import { RouteDevelopmentStatus, RouteReleaseStatus } from '@/libs/router/route'
import qs from 'qs'

import { RouteOptions, mergeRouteOptions } from './options'
import { buildPathHelperOptions } from './options'
import { pathTemplateFromParts } from './params'
import { RoutePart } from './types'

export interface PathHelper {
  (options?: RouteOptions): Partial<Path>
  canBuildPath: (options?: RouteOptions) => boolean
  withOptions: (options?: RouteOptions) => PathHelper
  baseOptions?: RouteOptions
  pathTemplate: string
  routeParts: RoutePart[]
  developmentStatus: RouteDevelopmentStatus
  releaseStatus: RouteReleaseStatus
  [key: string]: PathHelper
}

export interface DefinePathHelperProps {
  routeParts: RoutePart[]
  options?: RouteOptions
  children?: Record<string, PathHelper>
  developmentStatus?: RouteDevelopmentStatus
  releaseStatus?: RouteReleaseStatus
}

function definePathHelper({
  routeParts = [],
  options = {},
  children = {},
  developmentStatus = 'complete',
  releaseStatus = 'released',
}: DefinePathHelperProps): PathHelper {
  const baseOptions = options
  const pathTemplate = pathTemplateFromParts(routeParts)

  const buildOptions = (options?: RouteOptions) =>
    buildPathHelperOptions({
      parts: routeParts,
      options: mergeRouteOptions(baseOptions, options),
    })

  const pathHelper = (options: RouteOptions = {}): Partial<Path> => {
    const { queryParams, pathParams, relative } = buildOptions(options)

    const template = (relative ? '' : '/') + pathTemplate

    let pathname

    try {
      pathname = generatePath(template, pathParams)
    } catch (error) {
      throw new Error(`Failed building path for '${pathTemplate}': ${error}`)
    }

    return {
      pathname,
      search: qs.stringify(queryParams),
    }
  }

  pathHelper.withOptions = (options: RouteOptions) =>
    definePathHelper({
      routeParts,
      options: mergeRouteOptions(baseOptions, options),
      developmentStatus,
      releaseStatus,
    })

  /**
   * Checks if the route has all required
   * path parameters
   */
  pathHelper.canBuildPath = (options?: RouteOptions): boolean => {
    const { pathname } = pathHelper(options)
    return !pathname.includes(':') // checks that dynamic segments have all been resolved
  }

  pathHelper.baseOptions = baseOptions
  pathHelper.buildOptions = buildOptions
  pathHelper.pathTemplate = pathTemplate
  pathHelper.routeParts = routeParts
  pathHelper.developmentStatus = developmentStatus
  pathHelper.releaseStatus = releaseStatus

  Object.keys(children).forEach((name) => (pathHelper[name] = children[name]))

  return pathHelper
}

export { definePathHelper }

// JsFromRoutes CacheKey ae35fea0636c426d103c685d1e8ca6e0
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { UserModel } from '@/models/user'

export const usersSessionsApi = {
  new: defineEndpoint({
    controllerName: 'users/sessions',
    actionName: 'new',
    model: UserModel,
    parts: ['api', 'users', 'sign_in'],
    httpMethod: 'get',
    type: 'view',
  }),
  create: defineEndpoint({
    controllerName: 'users/sessions',
    actionName: 'create',
    model: UserModel,
    parts: ['api', 'users', 'sign_in'],
    httpMethod: 'post',
    type: 'create',
  }),
  destroy: defineEndpoint({
    controllerName: 'users/sessions',
    actionName: 'destroy',
    model: UserModel,
    parts: ['api', 'users', 'sign_out'],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}

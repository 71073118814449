import { To } from 'react-router-dom'

import { Loader } from '@/components/icons'
import { SidebarMenuAction, SidebarMenuItem, SidebarMenuLink } from '@/components/ui/sidebar'
import { useNavigationStates } from '@/hooks/use-navigation-states'
import { useResolvedRoute } from '@/hooks/use-resolved-route'
import { useRouteStatus } from '@/hooks/use-route-status'
import { PathHelper } from '@/libs/paths/path-helper'
import { indexRoutes } from '@/routes'

export interface NavItemProps extends Omit<React.ComponentProps<typeof SidebarMenuLink>, 'to'> {
  to?: To
  route?: PathHelper
  label?: string
  icon?: React.ReactNode
  action?: React.ReactNode
}

const NavItem = ({ label, icon, action, to, end = true, ...props }: NavItemProps) => {
  // TODO: Build out a proper To
  const { route = indexRoutes, ...linkProps } = props
  const { to: builtTo } = useResolvedRoute({ route })
  const finalTo = to ?? (props.route ? builtTo : undefined)

  const { isActive, isPending } = useNavigationStates({
    to: finalTo,
    end,
  })

  const { shouldShowRoute, releaseStatus } = useRouteStatus(route)

  if (!shouldShowRoute) return null

  const isDisabled =
    (releaseStatus === 'preview' && process.env.NODE_ENV !== 'development') || !finalTo

  return (
    <SidebarMenuItem>
      <SidebarMenuLink
        variant={'icon'}
        to={finalTo}
        tooltip={label}
        disabled={isDisabled}
        isActive={isActive}
        {...linkProps}
      >
        {icon && isPending ? <Loader className="animate-spin" /> : icon}
        {label ? label : null}
      </SidebarMenuLink>
      {action ? <SidebarMenuAction asChild>{action}</SidebarMenuAction> : null}
    </SidebarMenuItem>
  )
}

export { NavItem }

// JsFromRoutes CacheKey 06187a1988774024fd5a0f50124ee98a
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { DBTRelativeModel } from '@/models/dbt/relative'

export const dbtRelativesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/relatives',
    actionName: 'index',
    model: DBTRelativeModel,
    parts: ['api', 'dbt', 'relatives'],
    httpMethod: 'get',
    type: 'list',
  }),
}

import { createRoutes } from '@/libs/router/route'
import { SaleResource } from '@/resources/sale/resource'

export const SaleResourceRoutes = createRoutes({
  resource: SaleResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'sales',
      children: [
        {
          index: true,
          page: 'sales/index',
          query: SaleResource.query.index,
        },
        {
          path: ':id',
          query: SaleResource.query.show,
          children: [
            {
              index: true,
              page: 'sales/show',
            },
          ],
        },
      ],
    },
  ],
})

export const SaleRoutes = SaleResourceRoutes

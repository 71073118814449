import { CreateEndpoint, MutateEndpoint, UpdateEndpoint } from '@/libs/endpoint/endpoint'
import { RouteOptions, mergeRouteOptions } from '@/libs/paths/options'
import { ResourceRecord } from '@/libs/resource/record'
import { type QueryClient } from '@tanstack/react-query'

import { modelListQueryKeyFilter } from './list-query'
// TODO
// import { createListQueryKeyFn } from './list-query'
import { modelViewQueryKeyFilter } from './view-query'

const createMutation = <T extends ResourceRecord>(
  route: MutateEndpoint<T>,
  queryClient: QueryClient,
  baseOptions?: RouteOptions,
) => {
  const createOptions = (record?: T) =>
    mergeRouteOptions(baseOptions, {
      data: { [route.model.recordNameSingular]: record },
      pathParams: record,
    })

  return {
    mutationFn: (record: T) => route.request(createOptions(record)),

    onSuccess: (record: T) => {
      queryClient.setQueryData(modelViewQueryKeyFilter(route.model, record.data), record)

      // TODO: Consider making this more specific...
      void queryClient.invalidateQueries({
        queryKey: modelListQueryKeyFilter(route.model),
        type: 'active',
      })
    },
  }
}

const createUpsertMutation = <T extends ResourceRecord>(
  createRoute: CreateEndpoint<T>,
  updateRoute: UpdateEndpoint<T>,
  queryClient: QueryClient,
  baseOptions?: RouteOptions,
) => {
  const createOptions = (route: MutateEndpoint<T>, record?: T) =>
    mergeRouteOptions(baseOptions, {
      data: record,
      pathParams: record,
    })

  return {
    mutationFn: (record: T) => {
      const route = createRoute.model.isPersisted(record) ? updateRoute : createRoute

      return route.request(createOptions(route, record))
    },

    // Not sure we need anything special here for the `view` case--maybe for
    // the `list` one though
    onSuccess: (record: T, variables: T) => {
      const route = createRoute.model.isPersisted(variables) ? updateRoute : createRoute

      queryClient.setQueriesData(
        {
          queryKey: modelViewQueryKeyFilter(route.model, record.data),
          type: 'active',
        },
        record,
      )

      // TODO: Consider invalidating modelViewQueryKeyFilter as well

      // TODO: Consider making this more specific...
      void queryClient.invalidateQueries({
        queryKey: modelListQueryKeyFilter(route.model),
        type: 'active',
      })
    },
  }
}

export { createMutation, createUpsertMutation }

import { matchRoutes, useLocation, useParams } from 'react-router-dom'
import type { Location, Params, RouteMatch, RouteObject, To } from 'react-router-dom'

import { useResourceContext } from '@/contexts'
import { useAuth } from '@/contexts/auth'
import { definePathHelper, type PathHelper } from '@/libs/paths/path-helper'
import { RoutePart } from '@/libs/paths/types'
import { builtRoutes } from '@/router'

export interface UseResolvedRouteProps {
  route?: PathHelper
}

/**
 * Given either a `PathHelper` or `To`, will build a resolved `To` and
 * corresponding `href`, preferring to use the `PathHelper` if available.
 *
 * If neither are provided, will return `null` for both `to` and `href`
 */
export const useResolvedRoute = ({ route }: UseResolvedRouteProps) => {
  const location = useLocation()
  const stack = useResourceContext()
  const urlParams = useParams()
  const { hasPermission: authHasPermission } = useAuth()

  if (!route) {
    // this block will likely never be hit
    throw new Error('A route is expected')
  }

  const currentRouteMatches = matchBuiltRoutes(location)

  const builtTo = buildTo({ currentRouteMatches, stack, route, urlParams })

  const [toBuiltRoute] = builtTo ? matchBuiltRoutes(builtTo) : []

  const hasPermission = () => {
    return toBuiltRoute
      ? authHasPermission(
          toBuiltRoute.route.handle?.resourceName,
          toBuiltRoute.route.handle?.actionType,
        )
      : true
  }

  return {
    to: builtTo,
    href: builtTo ? hrefFromTo(builtTo) : undefined,
    hasPermission,
  }
}

/** Returns a 'to' target for the RouterLink component, from a route helper */
export function buildTo({
  currentRouteMatches,
  route,
  stack,
  urlParams,
}: {
  currentRouteMatches: RouteMatch[]
  route?: PathHelper
  stack: ReturnType<typeof useResourceContext>
  urlParams: Readonly<Params>
}) {
  const [firstRoutePart] = route.routeParts ?? []

  if (!firstRoutePart) return

  // Find proper starting point in tree
  const validParentIndex = currentRouteMatches.findIndex((match) => {
    const routeParts = match.route.handle?.routeParts
    if (!routeParts || match.route.index) return false

    return findPartInRoutes(firstRoutePart, routeParts) === undefined
  })

  const findRoute = (candidateParts: RoutePart[], currentRouteParent: RouteMatch) => {
    const routeParts = currentRouteParent.route.handle?.routeParts
    if (!routeParts || currentRouteParent.route.index) {
      return false
    }

    const finalParts = routeParts.concat(candidateParts)

    return findRouteInTree(finalParts, currentRouteParent.route)
  }

  const parentIndex =
    route.baseOptions?.relative === false ? currentRouteMatches.length - 1 : validParentIndex
  const parentRoutes = currentRouteMatches.slice(parentIndex)
  let foundRoute

  // Now traverse downwards from current route parent
  while (!foundRoute && parentRoutes.length > 0) {
    const currentRouteParent = parentRoutes.shift()
    foundRoute = findRoute(route.routeParts, currentRouteParent)
  }

  if (!foundRoute) {
    console.error(`Unable to resolve route for relative path ${route.pathTemplate}`)
    return
  }

  let finalRoute = definePathHelper({
    routeParts: foundRoute.handle?.routeParts,
    options: route.baseOptions,
  })

  finalRoute = finalRoute.withOptions({ stack })
  if (!finalRoute.canBuildPath()) {
    // ideally, this should not be needed
    finalRoute = finalRoute.withOptions({ pathParams: urlParams })
  }

  return finalRoute({ relative: false })
}

function findRouteInTree(routeParts: RoutePart[], currentRoute: RouteObject) {
  const currentRouteParts = currentRoute.handle?.routeParts as RoutePart[] | undefined

  if (!currentRouteParts) return

  if (
    isStartingSequence(currentRouteParts, routeParts) &&
    currentRouteParts.length === routeParts.length
  )
    return currentRoute

  if (!currentRoute.children) return

  const matchingChild = currentRoute.children.find((child) => {
    if (!child.handle?.routeParts || child.index) return false

    const start = isStartingSequence(child.handle?.routeParts, routeParts)
    return start
  })

  if (!matchingChild) return

  return findRouteInTree(routeParts, matchingChild)
}

function findPartInRoutes(routePart: RoutePart, currentParts: RoutePart[]) {
  return currentParts.find((part) => areRoutePartsEqual(routePart, part))
}

function isStartingSequence(routeParts: RoutePart[], existingParts: RoutePart[]) {
  if (routeParts.length > existingParts.length) return false

  return routeParts.every((part, index) => areRoutePartsEqual(part, existingParts[index]))
}

function areRoutePartsEqual(firstPart: RoutePart, secondPart: RoutePart) {
  if (typeof firstPart !== typeof secondPart) return false

  if (typeof firstPart === 'string') return firstPart === secondPart

  return firstPart.name === secondPart.name && firstPart.attributeId === secondPart.attributeId
}

/** Returns a plain href string */
export function hrefFromTo(to: To = ''): string {
  if (typeof to === 'string') {
    return to
  }

  let str = to.pathname ?? ''
  if (to.search) str += '?' + to.search
  return str
}

export function matchBuiltRoutes(location: Partial<Location> | string): RouteMatch[] {
  const matches = matchRoutes(builtRoutes, location) ?? []

  return matches.reverse()
}

// JsFromRoutes CacheKey 2f0d7ef5c608afef328c8327f7b55db6
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const companyDocumentsApi = {
  index: defineEndpointPathHelper('get', '/api/company_documents'),
  create: defineEndpointPathHelper('post', '/api/company_documents'),
  new: defineEndpointPathHelper('get', '/api/company_documents/new'),
  edit: defineEndpointPathHelper('get', '/api/company_documents/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/company_documents/:id'),
  update: defineEndpointPathHelper('patch', '/api/company_documents/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/company_documents/:id'),
}

// JsFromRoutes CacheKey 60a74a2e3822fd4122ba20014e3c19af
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { FinanceMonthlyEntryModel } from '@/models/finance/monthly_entry'

export const financeMonthlyEntriesApi = {
  index: defineEndpoint({
    controllerName: 'finance/monthly_entries',
    actionName: 'index',
    model: FinanceMonthlyEntryModel,
    parts: ['api', 'finance', 'monthly_entries'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'finance/monthly_entries',
    actionName: 'show',
    model: FinanceMonthlyEntryModel,
    parts: [
      'api',
      'finance',
      'monthly_entries',
      { name: 'monthlyEntryId', attributeId: 'FinanceMonthlyEntry:monthlyEntryId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}

import { createRoutes } from '@/libs/router/route'
import { OrganizationHorseResource } from '@/resources/organization_horse/resource'

export const OrganizationHorseResourceRoutes = createRoutes({
  resource: OrganizationHorseResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'organization_horses',
      children: [
        {
          index: true,
          page: 'organization_horses/index',
          query: OrganizationHorseResource.query.index,
        },
        {
          path: 'new',
          page: 'organization_horses/new',
          query: OrganizationHorseResource.query.new,
        },
        {
          path: ':id',
          query: OrganizationHorseResource.query.show,
          children: [
            {
              index: true,
              page: 'organization_horses/show',
            },
            {
              path: 'edit',
              query: OrganizationHorseResource.query.edit,
              page: 'organization_horses/edit',
            },
            {
              path: 'destroy',
              page: 'organization_horses/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const OrganizationHorseRoutes = OrganizationHorseResourceRoutes

import { createRoutes } from '@/libs/router/route'
import { BroodmareProspectResource } from '@/resources/broodmare_prospect/resource'

export const BroodmareProspectResourceRoutes = createRoutes({
  resource: BroodmareProspectResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'broodmare_prospects',
      children: [
        {
          index: true,
          page: 'broodmare_prospects/index',
          query: BroodmareProspectResource.query.index,
        },
        {
          path: 'new',
          page: 'broodmare_prospects/new',
          query: BroodmareProspectResource.query.new,
        },
        {
          path: ':id',
          query: BroodmareProspectResource.query.show,
          children: [
            {
              index: true,
              page: 'broodmare_prospects/show',
            },
            {
              path: 'edit',
              page: 'broodmare_prospects/edit',
            },
            {
              path: 'destroy',
              page: 'broodmare_prospects/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const BroodmareProspectRoutes = BroodmareProspectResourceRoutes

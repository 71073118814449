// JsFromRoutes CacheKey 36c53099e2bdc67dcb54a4c7e2c74727
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const deviseInvitationsApi = {
  edit: defineEndpointPathHelper('get', '/api/users/invitation/accept'),
  destroy: defineEndpointPathHelper('get', '/api/users/invitation/remove'),
  new: defineEndpointPathHelper('get', '/api/users/invitation/new'),
  update: defineEndpointPathHelper('patch', '/api/users/invitation'),
  create: defineEndpointPathHelper('post', '/api/users/invitation'),
}

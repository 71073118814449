// JsFromRoutes CacheKey c5a7396e420833b0985e51506dc0c0ac
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const stallionBreedingTargetsApi = {
  index: defineEndpointPathHelper('get', '/api/stallion_breeding_targets'),
  create: defineEndpointPathHelper('post', '/api/stallion_breeding_targets'),
  new: defineEndpointPathHelper('get', '/api/stallion_breeding_targets/new'),
  edit: defineEndpointPathHelper('get', '/api/stallion_breeding_targets/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/stallion_breeding_targets/:id'),
  update: defineEndpointPathHelper('patch', '/api/stallion_breeding_targets/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/stallion_breeding_targets/:id'),
}

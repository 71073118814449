import { forwardRef } from 'react'
import type { ElementType, ReactNode } from 'react'
import { LinkProps as RouterLinkProps, Link as RouterLink, To } from 'react-router-dom'

import { ChevronRight } from '@/components/icon'
import { Button, type ButtonProps } from '@/components/ui/button'
import { hrefFromTo, useResolvedRoute } from '@/hooks/use-resolved-route'
import { PathHelper } from '@/libs/paths/path-helper'

type Ref = HTMLButtonElement

export type BaseLinkProps = ButtonProps &
  Omit<RouterLinkProps, 'to'> & {
    to?: To
    href?: string
    noRouter?: boolean
    noChevron?: boolean
    noLinkFallback?: ElementType
    children?: ReactNode
  }

/*
 * BaseLink component allows for easy usage in scenarios
 * where links or actions may be missing. It also allows
 * for both React Router and non-React Router links.
 *
 * By default, a `Button` is rendered with a `RouterLink`
 * child. If `noRouter` is `true`, a normal `<a>` tag
 * will be used instead.
 */
const BaseLink = forwardRef<Ref, BaseLinkProps>(
  (
    { noRouter = false, noChevron = false, noLinkFallback = 'button', to, children, ...props },
    ref,
  ) => {
    if (!to) {
      const Fallback = noLinkFallback

      return (
        <Button asChild ref={ref} variant="inline" {...props}>
          <Fallback>{children}</Fallback>
        </Button>
      )
    }

    const { href = hrefFromTo(to), ...otherProps } = props

    const iconRight = noChevron ? null : <ChevronRight />

    return (
      <Button asChild iconRight={iconRight} ref={ref} variant="link" {...otherProps}>
        {noRouter ? <a href={href}>{children}</a> : <RouterLink to={to}>{children}</RouterLink>}
      </Button>
    )
  },
)

BaseLink.displayName = 'AppBaseLink'

export type ContextualLinkProps = Omit<BaseLinkProps, 'to'> & {
  route: PathHelper
}

/*
 * ContextualLink is a wrapper around `Link` that can additionally
 * resolve routes to a proper match under any context.
 */
const ContextualLink = forwardRef<Ref, ContextualLinkProps>(({ route, ...props }, ref) => {
  const { to, href } = useResolvedRoute({ route })

  return <BaseLink ref={ref} to={to} href={href} {...props} />
})

ContextualLink.displayName = 'AppContextualLink'

export type LinkProps = BaseLinkProps & {
  route?: PathHelper
  contextual?: boolean
}

const Link = forwardRef<Ref, LinkProps>(({ route, to, ...props }, ref) => {
  const { contextual = !!route } = props

  if (!route && !to) {
    console.warn('Link used without a `route` or `to`')
  }

  if (contextual && route) return <ContextualLink ref={ref} route={route} {...props} />

  if (!to && route) return <BaseLink ref={ref} to={route()} {...props} />

  return <BaseLink ref={ref} to={to} {...props} />
})

Link.displayName = 'AppLink'

export { BaseLink, ContextualLink, Link }

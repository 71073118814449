// JsFromRoutes CacheKey dfb48c1b232726c19ac48a17303918aa
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const hairSampleBatchesApi = {
  index: defineEndpointPathHelper('get', '/api/hair_sample_batches'),
  create: defineEndpointPathHelper('post', '/api/hair_sample_batches'),
  new: defineEndpointPathHelper('get', '/api/hair_sample_batches/new'),
  edit: defineEndpointPathHelper('get', '/api/hair_sample_batches/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/hair_sample_batches/:id'),
  update: defineEndpointPathHelper('patch', '/api/hair_sample_batches/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/hair_sample_batches/:id'),
}

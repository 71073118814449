// JsFromRoutes CacheKey 939f94051f73d17fcf1fd0b3b38eed1e
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { DBTCandidateHorseModel } from '@/models/dbt/candidate_horse'

export const dbtCandidateHorsesApi = {
  index: defineEndpoint({
    controllerName: 'dbt/candidate_horses',
    actionName: 'index',
    model: DBTCandidateHorseModel,
    parts: ['api', 'dbt', 'candidate_horses'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'dbt/candidate_horses',
    actionName: 'show',
    model: DBTCandidateHorseModel,
    parts: [
      'api',
      'dbt',
      'candidate_horses',
      { name: 'identifierHorseId', attributeId: 'DBTCandidateHorse:identifierHorseId' },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}

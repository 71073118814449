import { useState, useMemo } from "react";

import { useDataQuery } from "@/hooks/use_data_query";
import { useResourceT } from "@/hooks/use_resource_t";
import { ListQueryBuilder } from "@/libs/query/list-query";
import { ResourceRecord } from "@/libs/resource/record";
import { Resource } from "@/libs/resource/resource";
import {
  type ColumnPinningState,
  type ColumnDef,
  type ColumnFilter,
  type GroupingState,
  type Row,
  type SortingState,
  getExpandedRowModel,
  getFacetedUniqueValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getCoreRowModel,
  useReactTable,
  TableMeta,
} from "@tanstack/react-table";
import { useIsFirstRender } from "@uidotdev/usehooks";

import type { ColumnConfig } from "../types";

// import { useTableParams } from "./use-table-params";
import { useTableParams } from "./use-state-table-params";

export interface UseDataTableProps<TData extends ResourceRecord> {
  name: string;
  query: ListQueryBuilder<TData>;
  resource: Resource<TData>;
  clientSide: boolean;
  defaultPageSize: number;
  verticalScroll?: boolean;
  enableGrouping?: boolean;

  columns: ColumnDef<TData>[];
  meta?: TableMeta;
  searchColumn?: string;
  columnConfigs: ColumnConfig[];
  initialSorting: SortingState;
  initialPinnedColumns: ColumnPinningState;
  initialGroupedColumns: GroupingState;

  queryBatchSize?: number;
  transformData?: (data: TData[]) => TData[];
}

export const useDataTable = <TData extends ResourceRecord>({
  name,
  query,
  resource,
  clientSide = false,
  columns,
  searchColumn,
  meta,
  defaultPageSize = 25,
  verticalScroll = true,
  enableGrouping = false,
  initialSorting = [],
  initialPinnedColumns = {},
  // initialGroupedColumns = [],
  initialColumnFilters = [],
  queryBatchSize = 100,
  transformData,
}: UseDataTableProps<TData>) => {
  const { t } = useResourceT(resource);
  const isFirstRender = useIsFirstRender();

  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useTableParams({
    defaultSorting: initialSorting,
    defaultPageSize,
    // defaultColumnFilters: initialColumnFilters,
  });

  const initialColumnVisibility = useMemo(
    () =>
      columns.reduce(
        (result, col: ColumnDef<TData>) => ({
          ...result,
          [col.id]: col.meta?.isVisible ?? true,
        }),
        {},
      ),
    [columns],
  );

  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >(initialColumnVisibility);

  const [columnPinning, setColumnPinning] =
    useState<ColumnPinningState>(initialPinnedColumns);
  // const [grouping, setGrouping] = useState<GroupingState>(
  //   initialGroupedColumns,
  // );

  // TODO: Handle range filters
  const filterDefs = useMemo(() => {
    const defs = columnFilters
      .filter((e: ColumnFilter) => e.value != null)
      .map((e: ColumnFilter) => ({
        ...e.value,
        opName: e.value.opName,
        field: e.id.replace(".", "__"),
      }));

    return defs;
  }, [columnFilters]);

  const sortingDefs = useMemo(
    () =>
      sorting.map((sort) => ({
        field: sort.id.replace(".", "__"),
        direction: sort.desc ? ("desc" as const) : ("asc" as const),
      })),
    [sorting],
  );

  const paginationDefs = useMemo(
    () =>
      clientSide
        ? {}
        : {
            page: (pagination.pageIndex ?? 0) + 1,
            pageSize: pagination.pageSize,
          },
    [clientSide, pagination],
  );

  const dataQuery = useMemo(
    () =>
      // TODO: Implement a proper context count
      clientSide
        ? query
        : query.withOptions({
            q: {
              filters: filterDefs,
              sorts: sortingDefs,
              pagination: paginationDefs,
            },
          }),
    [clientSide, query, filterDefs, sortingDefs, paginationDefs],
  );

  const { count, flatData } = useDataQuery({
    query: dataQuery,
    resource,
    clientSide,
    batchSize: queryBatchSize,
  });

  const tableData = useMemo(
    () => (transformData ? transformData(flatData) : flatData),
    [flatData],
  );

  const getRowId = (row: TData, index: number, parent?: Row<TData>) => {
    if (resource.model.primaryKey === undefined) {
      return index;
    }

    return parent
      ? [parent.id, resource.model.getRecordId(row)].join(".")
      : resource.model.getRecordId(row);
  };

  /*
   * For some unknown reason, the table behaves differently
   * when a row model isn't passed in as an option at all vs
   * passed in set to undefined.
   *
   * That's why this function is important
   */
  const featureSpecificOptions = () => {
    const baseOptions = clientSide
      ? {
          rowCount: count,
          getFilteredRowModel: getFilteredRowModel(),
          getSortedRowModel: getSortedRowModel(),
          getFacetedRowModel: getFacetedRowModel(),
          getFacetedUniqueValues: getFacetedUniqueValues(),
        }
      : {
          manualFilters: true,
          manualSorts: true,
        };

    const paginationOptions = clientSide
      ? {
          getPaginationRowModel: getPaginationRowModel(),
        }
      : {
          manualPagination: true,
        };

    const groupingOptions =
      enableGrouping && clientSide
        ? {
            enableExpanding: true,
            enableGrouping: true,
            groupedColumnMode: "remove",
            getExpandedRowModel: getExpandedRowModel(),
            getGroupedRowModel: getGroupedRowModel(),
          }
        : { manualPagination: true };

    return {
      ...baseOptions,
      ...paginationOptions,
      // ...groupingOptions,
    };
  };

  const table = useReactTable<TData>({
    data: tableData,
    meta: {
      name,
      clientSide,
      verticalScroll,
      resource,
      t,
      searchColumn,
      // TODO: Implement a proper context count
      contextCount: count,
      ...meta,
    },
    columns,

    autoResetPageIndex: !isFirstRender,

    state: {
      columnFilters,
      columnPinning,
      columnVisibility,
      // grouping,
      pagination,
      sorting,
    },

    onColumnFiltersChange: setColumnFilters,
    onColumnPinningChange: setColumnPinning,
    onColumnVisibilityChange: setColumnVisibility,
    // onGroupingChange: setGrouping,

    // Because of this bug: https://github.com/TanStack/table/issues/5026
    // Need to "disable" pagination when grouping otherwise table enters
    // infinite re-render loop
    // onPaginationChange: enableGrouping
    //   ? () => {
    //       return;
    //     }
    //   : setPagination,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    getRowId,

    getCoreRowModel: getCoreRowModel(),

    ...featureSpecificOptions(),
  });

  return { table };
};

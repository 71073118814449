// JsFromRoutes CacheKey ebf47ae954934d52a8e726c6932aa3cf
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { MonthlyOwnershipsByDivisionModel } from '@/models/monthly_ownerships_by_division'

export const monthlyOwnershipsByDivisionsApi = {
  index: defineEndpoint({
    controllerName: 'monthly_ownerships_by_divisions',
    actionName: 'index',
    model: MonthlyOwnershipsByDivisionModel,
    parts: ['api', 'monthly_ownerships_by_divisions'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'monthly_ownerships_by_divisions',
    actionName: 'show',
    model: MonthlyOwnershipsByDivisionModel,
    parts: [
      'api',
      'monthly_ownerships_by_divisions',
      {
        name: 'monthlyOwnershipsByDivisionId',
        attributeId: 'MonthlyOwnershipsByDivision:monthlyOwnershipsByDivisionId',
      },
    ],
    httpMethod: 'get',
    type: 'view',
  }),
}

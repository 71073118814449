import { createRoutes } from '@/libs/router/route'
import { StudFeeResource } from '@/resources/stud_fee/resource'

export const StudFeeResourceRoutes = createRoutes({
  resource: StudFeeResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'stud_fees',
      children: [
        {
          index: true,
          page: 'stud_fees/index',
          query: StudFeeResource.query.index,
        },
        {
          path: 'new',
          page: 'stud_fees/new',
          query: StudFeeResource.query.new,
        },
        {
          path: ':id',
          query: StudFeeResource.query.show,
          children: [
            {
              index: true,
              page: 'stud_fees/show',
            },
            {
              path: 'edit',
              query: StudFeeResource.query.edit,
              page: 'stud_fees/edit',
            },
            {
              path: 'destroy',
              page: 'stud_fees/destroy',
            },
          ],
        },
      ],
    },
  ],
})

export const StudFeeRoutes = StudFeeResourceRoutes

import { createRoutes } from '@/libs/router/route'
import { DNASetResource } from '@/resources/dna_set/resource'

export const DNASetResourceRoutes = createRoutes({
  resource: DNASetResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'dna_sets',
      children: [
        {
          index: true,
          page: 'dna_sets/index',
          query: DNASetResource.query.index,
        },
        {
          path: ':id',
          query: DNASetResource.query.show,
          children: [
            {
              index: true,
              page: 'dna_sets/show',
            },
          ],
        },
      ],
    },
  ],
})

export const DNASetRoutes = DNASetResourceRoutes

// This file is automatically generated. Do not edit.

import { definePathHelper } from '@/libs/paths/path-helper'

const indexRoutes = definePathHelper({
  routeParts: [],

  children: {},
})

const accountsRoutes = {
  index: definePathHelper({
    routeParts: ['accounts'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['accounts', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['accounts', { name: 'accountId', attributeId: 'Account:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['accounts', { name: 'accountId', attributeId: 'Account:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['accounts', { name: 'accountId', attributeId: 'Account:id' }, 'destroy'],

    children: {},
  }),
}

const appraisalsRoutes = {
  index: definePathHelper({
    routeParts: ['appraisals'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['appraisals', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['appraisals', { name: 'appraisalId', attributeId: 'Appraisal:id' }, 'destroy'],

    children: {},
  }),
}

const bloodstockRoutes = {
  index: definePathHelper({
    routeParts: ['bloodstock'],

    children: {},
  }),

  crops: definePathHelper({
    routeParts: ['bloodstock', 'crops'],

    children: {},
  }),

  racehorses: definePathHelper({
    routeParts: ['bloodstock', 'racehorses'],

    children: {},
  }),

  broodmares: definePathHelper({
    routeParts: ['bloodstock', 'broodmares'],

    children: {},
  }),

  stallions: definePathHelper({
    routeParts: ['bloodstock', 'stallions'],

    children: {},
  }),
}

const booksRoutes = {
  index: definePathHelper({
    routeParts: ['books'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['books', { name: 'bookYear', attributeId: 'Season:year' }],

    children: {},
  }),

  targets: definePathHelper({
    routeParts: ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'targets'],

    children: {},
  }),

  seasons: definePathHelper({
    routeParts: ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'seasons'],

    children: {
      new: definePathHelper({
        routeParts: ['books', { name: 'bookYear', attributeId: 'Season:year' }, 'seasons', 'new'],

        children: {
          covers: {
            index: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                'new',
                'covers',
              ],

              children: {},
            }),

            new: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                'new',
                'covers',
                'new',
              ],

              children: {},
            }),

            show: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                'new',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
              ],

              children: {},
            }),

            edit: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                'new',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
                'edit',
              ],

              children: {},
            }),

            destroy: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                'new',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
                'destroy',
              ],

              children: {},
            }),
          },
        },
      }),

      show: definePathHelper({
        routeParts: [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
        ],

        children: {},
      }),

      edit: definePathHelper({
        routeParts: [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
          'edit',
        ],

        children: {
          covers: {
            index: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                { name: 'seasonId', attributeId: 'Season:id' },
                'edit',
                'covers',
              ],

              children: {},
            }),

            new: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                { name: 'seasonId', attributeId: 'Season:id' },
                'edit',
                'covers',
                'new',
              ],

              children: {},
            }),

            show: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                { name: 'seasonId', attributeId: 'Season:id' },
                'edit',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
              ],

              children: {},
            }),

            edit: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                { name: 'seasonId', attributeId: 'Season:id' },
                'edit',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
                'edit',
              ],

              children: {},
            }),

            destroy: definePathHelper({
              routeParts: [
                'books',
                { name: 'bookYear', attributeId: 'Season:year' },
                'seasons',
                { name: 'seasonId', attributeId: 'Season:id' },
                'edit',
                'covers',
                { name: 'coverId', attributeId: 'Cover:id' },
                'destroy',
              ],

              children: {},
            }),
          },
        },
      }),

      destroy: definePathHelper({
        routeParts: [
          'books',
          { name: 'bookYear', attributeId: 'Season:year' },
          'seasons',
          { name: 'seasonId', attributeId: 'Season:id' },
          'destroy',
        ],

        children: {},
      }),

      horses: {
        new: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'destroy',
          ],

          children: {},
        }),

        fiveCross: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'five_cross',
          ],

          children: {},
        }),

        pedigree: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'pedigree',
          ],

          children: {},
        }),

        progeny: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'progeny',
          ],

          children: {},
        }),

        matings: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'matings',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'matings',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'matings',
              { name: 'matingId', attributeId: 'Mating:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'matings',
              { name: 'matingId', attributeId: 'Mating:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'matings',
              { name: 'matingId', attributeId: 'Mating:id' },
              'destroy',
            ],

            children: {},
          }),
        },

        sales: definePathHelper({
          routeParts: [
            'books',
            { name: 'bookYear', attributeId: 'Season:year' },
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'sales',
          ],

          children: {},
        }),

        appraisals: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'appraisals',
              { name: 'appraisalId', attributeId: 'Appraisal:id' },
              'destroy',
            ],

            children: {},
          }),
        },

        racing: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racing',
            ],

            children: {},
          }),
        },

        conformationNotes: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'conformation_notes',
              {
                name: 'conformationNoteId',
                attributeId: 'ConformationNote:id',
              },
              'destroy',
            ],

            children: {},
          }),
        },

        documents: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'documents',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'documents',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'documents',
              { name: 'documentId', attributeId: 'Document:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'documents',
              { name: 'documentId', attributeId: 'Document:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'documents',
              { name: 'documentId', attributeId: 'Document:id' },
              'destroy',
            ],

            children: {},
          }),
        },

        images: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'images',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'images',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'images',
              { name: 'imageId', attributeId: 'Image:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'images',
              { name: 'imageId', attributeId: 'Image:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'images',
              { name: 'imageId', attributeId: 'Image:id' },
              'destroy',
            ],

            children: {},
          }),
        },

        organizationHorses: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'organization_horses',
              {
                name: 'organizationHorseId',
                attributeId: 'OrganizationHorse:id',
              },
              'destroy',
            ],

            children: {},
          }),
        },

        racehorseComments: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'racehorse_comments',
              {
                name: 'racehorseCommentId',
                attributeId: 'RacehorseComment:id',
              },
              'destroy',
            ],

            children: {},
          }),
        },

        studFees: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'stud_fees',
              { name: 'studFeeId', attributeId: 'StudFee:id' },
              'destroy',
            ],

            children: {},
          }),
        },

        trainingReports: {
          index: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'books',
              { name: 'bookYear', attributeId: 'Season:year' },
              'seasons',
              'horses',
              { name: 'horseId', attributeId: 'Horse:id' },
              'training_reports',
              { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
              'destroy',
            ],

            children: {},
          }),
        },
      },
    },
  }),
}

const broodmaresRoutes = {
  claiming: definePathHelper({
    routeParts: ['broodmares', 'claiming'],

    children: {
      broodmareProspects: {
        index: definePathHelper({
          routeParts: ['broodmares', 'claiming', 'broodmare_prospects'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: ['broodmares', 'claiming', 'broodmare_prospects', 'new'],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'broodmares',
            'claiming',
            'broodmare_prospects',
            {
              name: 'broodmareProspectId',
              attributeId: 'BroodmareProspect:id',
            },
            'destroy',
          ],

          children: {},
        }),
      },
    },
  }),
}

const broodmareProspectsRoutes = {
  index: definePathHelper({
    routeParts: ['broodmare_prospects'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['broodmare_prospects', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'broodmare_prospects',
      { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'broodmare_prospects',
      { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'broodmare_prospects',
      { name: 'broodmareProspectId', attributeId: 'BroodmareProspect:id' },
      'destroy',
    ],

    children: {},
  }),
}

const conformationNotesRoutes = {
  index: definePathHelper({
    routeParts: ['conformation_notes'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['conformation_notes', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'conformation_notes',
      { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'conformation_notes',
      { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'conformation_notes',
      { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
      'destroy',
    ],

    children: {},
  }),
}

const coversRoutes = {
  index: definePathHelper({
    routeParts: ['covers'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['covers', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['covers', { name: 'coverId', attributeId: 'Cover:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['covers', { name: 'coverId', attributeId: 'Cover:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['covers', { name: 'coverId', attributeId: 'Cover:id' }, 'destroy'],

    children: {},
  }),
}

const dnaSetsRoutes = {
  index: definePathHelper({
    routeParts: ['dna_sets'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['dna_sets', { name: 'dnaSetId', attributeId: 'DNASet:id' }],

    children: {},
  }),
}

const documentsRoutes = {
  index: definePathHelper({
    routeParts: ['documents'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['documents', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['documents', { name: 'documentId', attributeId: 'Document:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['documents', { name: 'documentId', attributeId: 'Document:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['documents', { name: 'documentId', attributeId: 'Document:id' }, 'destroy'],

    children: {},
  }),
}

const entitiesRoutes = {
  index: definePathHelper({
    routeParts: ['entities'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['entities', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['entities', { name: 'entityId', attributeId: 'Entity:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['entities', { name: 'entityId', attributeId: 'Entity:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['entities', { name: 'entityId', attributeId: 'Entity:id' }, 'destroy'],

    children: {},
  }),
}

const financeRoutes = definePathHelper({
  routeParts: ['finance'],

  children: {},
})

const horsesRoutes = {
  new: definePathHelper({
    routeParts: ['horses', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }],

    children: {
      fiveCross: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'five_cross'],

        children: {},
      }),

      pedigree: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'pedigree'],

        children: {},
      }),

      progeny: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'progeny'],

        children: {},
      }),

      matings: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'matings'],

        children: {},
      }),

      edit: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'edit'],

        children: {},
      }),

      destroy: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'destroy'],

        children: {},
      }),

      sales: definePathHelper({
        routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'sales'],

        children: {},
      }),

      appraisals: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'appraisals', 'new'],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      racing: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racing'],

          children: {},
        }),
      },

      conformationNotes: {
        index: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      documents: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'documents'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'documents', 'new'],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      images: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'images'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'images', 'new'],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      organizationHorses: {
        index: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'destroy',
          ],

          children: {},
        }),
      },

      racehorseComments: {
        index: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      studFees: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'stud_fees', 'new'],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      trainingReports: {
        index: definePathHelper({
          routeParts: ['horses', { name: 'horseId', attributeId: 'Horse:id' }, 'training_reports'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'destroy',
          ],

          children: {},
        }),
      },
    },
  }),
}

const horseDetailsRoutes = {
  index: definePathHelper({
    routeParts: ['horse_details'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'horse_details',
      {
        name: 'horseDetailHorseDetailId',
        attributeId: 'HorseDetail:horseDetailId',
      },
    ],

    children: {},
  }),
}

const imagesRoutes = {
  index: definePathHelper({
    routeParts: ['images'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['images', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['images', { name: 'imageId', attributeId: 'Image:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['images', { name: 'imageId', attributeId: 'Image:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['images', { name: 'imageId', attributeId: 'Image:id' }, 'destroy'],

    children: {},
  }),
}

const matingsRoutes = {
  index: definePathHelper({
    routeParts: ['matings'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['matings', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['matings', { name: 'matingId', attributeId: 'Mating:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['matings', { name: 'matingId', attributeId: 'Mating:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['matings', { name: 'matingId', attributeId: 'Mating:id' }, 'destroy'],

    children: {},
  }),
}

const operationsRoutes = {
  index: definePathHelper({
    routeParts: ['operations'],

    children: {},
  }),
}

const organizationsRoutes = {
  index: definePathHelper({
    routeParts: ['organizations'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['organizations', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['organizations', { name: 'organizationId', attributeId: 'Organization:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'organizations',
      { name: 'organizationId', attributeId: 'Organization:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'organizations',
      { name: 'organizationId', attributeId: 'Organization:id' },
      'destroy',
    ],

    children: {},
  }),
}

const organizationHorsesRoutes = {
  index: definePathHelper({
    routeParts: ['organization_horses'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['organization_horses', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'organization_horses',
      { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'organization_horses',
      { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'organization_horses',
      { name: 'organizationHorseId', attributeId: 'OrganizationHorse:id' },
      'destroy',
    ],

    children: {},
  }),
}

const pregnancyChecksRoutes = {
  index: definePathHelper({
    routeParts: ['pregnancy_checks'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['pregnancy_checks', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'pregnancy_checks',
      { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'pregnancy_checks',
      { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'pregnancy_checks',
      { name: 'pregnancyCheckId', attributeId: 'PregnancyCheck:id' },
      'destroy',
    ],

    children: {},
  }),
}

const racehorseCommentsRoutes = {
  index: definePathHelper({
    routeParts: ['racehorse_comments'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['racehorse_comments', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'racehorse_comments',
      { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'racehorse_comments',
      { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'racehorse_comments',
      { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
      'destroy',
    ],

    children: {},
  }),
}

const salesRoutes = {
  index: definePathHelper({
    routeParts: ['sales'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['sales', { name: 'saleId', attributeId: 'Sale:id' }],

    children: {},
  }),
}

const saleRecordsRoutes = {
  index: definePathHelper({
    routeParts: ['sale_records'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['sale_records', { name: 'saleRecordId', attributeId: 'SaleRecord:id' }],

    children: {},
  }),
}

const saleRecordDetailsRoutes = {
  index: definePathHelper({
    routeParts: ['sale_record_details'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'sale_record_details',
      {
        name: 'saleRecordDetailSaleRecordDetailId',
        attributeId: 'SaleRecordDetail:saleRecordDetailId',
      },
    ],

    children: {},
  }),
}

const seasonsRoutes = definePathHelper({
  routeParts: ['seasons'],

  children: {
    new: definePathHelper({
      routeParts: ['seasons', 'new'],

      children: {
        covers: {
          index: definePathHelper({
            routeParts: ['seasons', 'new', 'covers'],

            children: {},
          }),

          new: definePathHelper({
            routeParts: ['seasons', 'new', 'covers', 'new'],

            children: {},
          }),

          show: definePathHelper({
            routeParts: ['seasons', 'new', 'covers', { name: 'coverId', attributeId: 'Cover:id' }],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'seasons',
              'new',
              'covers',
              { name: 'coverId', attributeId: 'Cover:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'seasons',
              'new',
              'covers',
              { name: 'coverId', attributeId: 'Cover:id' },
              'destroy',
            ],

            children: {},
          }),
        },
      },
    }),

    show: definePathHelper({
      routeParts: ['seasons', { name: 'seasonId', attributeId: 'Season:id' }],

      children: {},
    }),

    edit: definePathHelper({
      routeParts: ['seasons', { name: 'seasonId', attributeId: 'Season:id' }, 'edit'],

      children: {
        covers: {
          index: definePathHelper({
            routeParts: [
              'seasons',
              { name: 'seasonId', attributeId: 'Season:id' },
              'edit',
              'covers',
            ],

            children: {},
          }),

          new: definePathHelper({
            routeParts: [
              'seasons',
              { name: 'seasonId', attributeId: 'Season:id' },
              'edit',
              'covers',
              'new',
            ],

            children: {},
          }),

          show: definePathHelper({
            routeParts: [
              'seasons',
              { name: 'seasonId', attributeId: 'Season:id' },
              'edit',
              'covers',
              { name: 'coverId', attributeId: 'Cover:id' },
            ],

            children: {},
          }),

          edit: definePathHelper({
            routeParts: [
              'seasons',
              { name: 'seasonId', attributeId: 'Season:id' },
              'edit',
              'covers',
              { name: 'coverId', attributeId: 'Cover:id' },
              'edit',
            ],

            children: {},
          }),

          destroy: definePathHelper({
            routeParts: [
              'seasons',
              { name: 'seasonId', attributeId: 'Season:id' },
              'edit',
              'covers',
              { name: 'coverId', attributeId: 'Cover:id' },
              'destroy',
            ],

            children: {},
          }),
        },
      },
    }),

    destroy: definePathHelper({
      routeParts: ['seasons', { name: 'seasonId', attributeId: 'Season:id' }, 'destroy'],

      children: {},
    }),

    horses: {
      new: definePathHelper({
        routeParts: ['seasons', 'horses', 'new'],

        children: {},
      }),

      show: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }],

        children: {},
      }),

      edit: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'edit'],

        children: {},
      }),

      destroy: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'destroy'],

        children: {},
      }),

      fiveCross: definePathHelper({
        routeParts: [
          'seasons',
          'horses',
          { name: 'horseId', attributeId: 'Horse:id' },
          'five_cross',
        ],

        children: {},
      }),

      pedigree: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'pedigree'],

        children: {},
      }),

      progeny: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'progeny'],

        children: {},
      }),

      matings: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'matings',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'matings',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'matings',
            { name: 'matingId', attributeId: 'Mating:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'matings',
            { name: 'matingId', attributeId: 'Mating:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'matings',
            { name: 'matingId', attributeId: 'Mating:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      sales: definePathHelper({
        routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'sales'],

        children: {},
      }),

      appraisals: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'appraisals',
            { name: 'appraisalId', attributeId: 'Appraisal:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      racing: {
        index: definePathHelper({
          routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'racing'],

          children: {},
        }),
      },

      conformationNotes: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'conformation_notes',
            { name: 'conformationNoteId', attributeId: 'ConformationNote:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      documents: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'documents',
            { name: 'documentId', attributeId: 'Document:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      images: {
        index: definePathHelper({
          routeParts: ['seasons', 'horses', { name: 'horseId', attributeId: 'Horse:id' }, 'images'],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'images',
            { name: 'imageId', attributeId: 'Image:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      organizationHorses: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'organization_horses',
            {
              name: 'organizationHorseId',
              attributeId: 'OrganizationHorse:id',
            },
            'destroy',
          ],

          children: {},
        }),
      },

      racehorseComments: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'racehorse_comments',
            { name: 'racehorseCommentId', attributeId: 'RacehorseComment:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      studFees: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'stud_fees',
            { name: 'studFeeId', attributeId: 'StudFee:id' },
            'destroy',
          ],

          children: {},
        }),
      },

      trainingReports: {
        index: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
          ],

          children: {},
        }),

        new: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            'new',
          ],

          children: {},
        }),

        show: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
          ],

          children: {},
        }),

        edit: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'edit',
          ],

          children: {},
        }),

        destroy: definePathHelper({
          routeParts: [
            'seasons',
            'horses',
            { name: 'horseId', attributeId: 'Horse:id' },
            'training_reports',
            { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
            'destroy',
          ],

          children: {},
        }),
      },
    },
  },
})

const sharesRoutes = {
  index: definePathHelper({
    routeParts: ['shares'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['shares', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['shares', { name: 'shareId', attributeId: 'Share:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['shares', { name: 'shareId', attributeId: 'Share:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['shares', { name: 'shareId', attributeId: 'Share:id' }, 'destroy'],

    children: {},
  }),
}

const shortlistsRoutes = {
  index: definePathHelper({
    routeParts: ['shortlists'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['shortlists', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['shortlists', { name: 'shortlistId', attributeId: 'Shortlist:id' }, 'destroy'],

    children: {},
  }),
}

const stallionsRoutes = {
  index: definePathHelper({
    routeParts: ['stallions'],

    children: {},
  }),

  prospects: definePathHelper({
    routeParts: ['stallions', 'prospects'],

    children: {},
  }),

  racing: definePathHelper({
    routeParts: ['stallions', 'racing'],

    children: {},
  }),

  sales: definePathHelper({
    routeParts: ['stallions', 'sales'],

    children: {},
  }),

  crops: definePathHelper({
    routeParts: ['stallions', 'crops'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }],

    children: {
      racehorses: definePathHelper({
        routeParts: ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'racehorses'],

        children: {
          racehorseComments: {
            index: definePathHelper({
              routeParts: [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
              ],

              children: {},
            }),

            new: definePathHelper({
              routeParts: [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                'new',
              ],

              children: {},
            }),

            show: definePathHelper({
              routeParts: [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
              ],

              children: {},
            }),

            edit: definePathHelper({
              routeParts: [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
                'edit',
              ],

              children: {},
            }),

            destroy: definePathHelper({
              routeParts: [
                'stallions',
                { name: 'stallionId', attributeId: 'Horse:id' },
                'racehorses',
                'racehorse_comments',
                {
                  name: 'racehorseCommentId',
                  attributeId: 'RacehorseComment:id',
                },
                'destroy',
              ],

              children: {},
            }),
          },
        },
      }),

      sales: definePathHelper({
        routeParts: ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'sales'],

        children: {},
      }),

      crops: definePathHelper({
        routeParts: ['stallions', { name: 'stallionId', attributeId: 'Horse:id' }, 'crops'],

        children: {},
      }),
    },
  }),
}

const studFeesRoutes = {
  index: definePathHelper({
    routeParts: ['stud_fees'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['stud_fees', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['stud_fees', { name: 'studFeeId', attributeId: 'StudFee:id' }, 'destroy'],

    children: {},
  }),
}

const trainingReportsRoutes = {
  index: definePathHelper({
    routeParts: ['training_reports'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['training_reports', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: [
      'training_reports',
      { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
    ],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: [
      'training_reports',
      { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
      'edit',
    ],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: [
      'training_reports',
      { name: 'trainingReportId', attributeId: 'TrainingReport:id' },
      'destroy',
    ],

    children: {},
  }),
}

const usersRoutes = {
  index: definePathHelper({
    routeParts: ['users'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['users', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['users', { name: 'userId', attributeId: 'User:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['users', { name: 'userId', attributeId: 'User:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['users', { name: 'userId', attributeId: 'User:id' }, 'destroy'],

    children: {},
  }),
}

const videosRoutes = {
  index: definePathHelper({
    routeParts: ['videos'],

    children: {},
  }),

  new: definePathHelper({
    routeParts: ['videos', 'new'],

    children: {},
  }),

  show: definePathHelper({
    routeParts: ['videos', { name: 'videoId', attributeId: 'Video:id' }],

    children: {},
  }),

  edit: definePathHelper({
    routeParts: ['videos', { name: 'videoId', attributeId: 'Video:id' }, 'edit'],

    children: {},
  }),

  destroy: definePathHelper({
    routeParts: ['videos', { name: 'videoId', attributeId: 'Video:id' }, 'destroy'],

    children: {},
  }),
}

const loginRoutes = definePathHelper({
  routeParts: ['login'],

  children: {},
})

export const PathHelpers = {
  index: indexRoutes,
  accounts: accountsRoutes,
  appraisals: appraisalsRoutes,
  bloodstock: bloodstockRoutes,
  books: booksRoutes,
  broodmares: broodmaresRoutes,
  broodmareProspects: broodmareProspectsRoutes,
  conformationNotes: conformationNotesRoutes,
  covers: coversRoutes,
  dnaSets: dnaSetsRoutes,
  documents: documentsRoutes,
  entities: entitiesRoutes,
  finance: financeRoutes,
  horses: horsesRoutes,
  horseDetails: horseDetailsRoutes,
  images: imagesRoutes,
  matings: matingsRoutes,
  operations: operationsRoutes,
  organizations: organizationsRoutes,
  organizationHorses: organizationHorsesRoutes,
  pregnancyChecks: pregnancyChecksRoutes,
  racehorseComments: racehorseCommentsRoutes,
  sales: salesRoutes,
  saleRecords: saleRecordsRoutes,
  saleRecordDetails: saleRecordDetailsRoutes,
  seasons: seasonsRoutes,
  shares: sharesRoutes,
  shortlists: shortlistsRoutes,
  stallions: stallionsRoutes,
  studFees: studFeesRoutes,
  trainingReports: trainingReportsRoutes,
  users: usersRoutes,
  videos: videosRoutes,
  login: loginRoutes,
}

export {
  indexRoutes,
  accountsRoutes,
  appraisalsRoutes,
  bloodstockRoutes,
  booksRoutes,
  broodmaresRoutes,
  broodmareProspectsRoutes,
  conformationNotesRoutes,
  coversRoutes,
  dnaSetsRoutes,
  documentsRoutes,
  entitiesRoutes,
  financeRoutes,
  horsesRoutes,
  horseDetailsRoutes,
  imagesRoutes,
  matingsRoutes,
  operationsRoutes,
  organizationsRoutes,
  organizationHorsesRoutes,
  pregnancyChecksRoutes,
  racehorseCommentsRoutes,
  salesRoutes,
  saleRecordsRoutes,
  saleRecordDetailsRoutes,
  seasonsRoutes,
  sharesRoutes,
  shortlistsRoutes,
  stallionsRoutes,
  studFeesRoutes,
  trainingReportsRoutes,
  usersRoutes,
  videosRoutes,
  loginRoutes,
}

// JsFromRoutes CacheKey 528e24ca3f3632b06edefceb205c9cff
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const physicalMeasurementsApi = {
  index: defineEndpointPathHelper('get', '/api/physical_measurements'),
  create: defineEndpointPathHelper('post', '/api/physical_measurements'),
  new: defineEndpointPathHelper('get', '/api/physical_measurements/new'),
  edit: defineEndpointPathHelper('get', '/api/physical_measurements/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/physical_measurements/:id'),
  update: defineEndpointPathHelper('patch', '/api/physical_measurements/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/physical_measurements/:id'),
}

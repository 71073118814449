import { createRoutes } from '@/libs/router/route'
import { SaleRecordResource } from '@/resources/sale_record/resource'

export const SaleRecordResourceRoutes = createRoutes({
  resource: SaleRecordResource,
  defaultDevelopmentStatus: 'planned',
  routes: [
    {
      path: 'sale_records',
      children: [
        {
          index: true,
          page: 'sale_records/index',
          query: SaleRecordResource.query.index,
        },
        {
          path: ':id',
          query: SaleRecordResource.query.show,
          children: [
            {
              index: true,
              page: 'sale_records/show',
            },
          ],
        },
      ],
    },
  ],
})

export const SaleRecordRoutes = SaleRecordResourceRoutes

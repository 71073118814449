// JsFromRoutes CacheKey 1b0b230123584a016712def73d0540fe
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { SaleRecordModel } from '@/models/sale_record'

export const saleRecordsApi = {
  index: defineEndpoint({
    controllerName: 'sale_records',
    actionName: 'index',
    model: SaleRecordModel,
    parts: ['api', 'sale_records'],
    httpMethod: 'get',
    type: 'list',
  }),
  show: defineEndpoint({
    controllerName: 'sale_records',
    actionName: 'show',
    model: SaleRecordModel,
    parts: ['api', 'sale_records', { name: 'id', attributeId: 'SaleRecord:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
}

// JsFromRoutes CacheKey 0996db05c8205f09243f05a45ad2c6c1
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpoint } from '@/libs/endpoint/endpoint'
import { OrganizationHorseModel } from '@/models/organization_horse'

export const organizationHorsesApi = {
  index: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'index',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses'],
    httpMethod: 'get',
    type: 'list',
  }),
  create: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'create',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses'],
    httpMethod: 'post',
    type: 'create',
  }),
  new: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'new',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses', 'new'],
    httpMethod: 'get',
    type: 'view',
  }),
  edit: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'edit',
    model: OrganizationHorseModel,
    parts: [
      'api',
      'organization_horses',
      { name: 'id', attributeId: 'OrganizationHorse:id' },
      'edit',
    ],
    httpMethod: 'get',
    type: 'view',
  }),
  show: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'show',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses', { name: 'id', attributeId: 'OrganizationHorse:id' }],
    httpMethod: 'get',
    type: 'view',
  }),
  update: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'update',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses', { name: 'id', attributeId: 'OrganizationHorse:id' }],
    httpMethod: 'patch',
    type: 'update',
  }),
  destroy: defineEndpoint({
    controllerName: 'organization_horses',
    actionName: 'destroy',
    model: OrganizationHorseModel,
    parts: ['api', 'organization_horses', { name: 'id', attributeId: 'OrganizationHorse:id' }],
    httpMethod: 'delete',
    type: 'destroy',
  }),
}

// JsFromRoutes CacheKey 1a152b08d6a268ff8dbedcf8b064c4f6
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const bloodstockInventoryMonthlyApi = {
  index: defineEndpointPathHelper('get', '/api/bloodstock_inventory_monthly'),
  show: defineEndpointPathHelper('get', '/api/bloodstock_inventory_monthly/:id'),
}

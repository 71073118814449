// JsFromRoutes CacheKey cef8944750b592e63bce417fdfee8154
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const stallionBreedingEstimatesApi = {
  index: defineEndpointPathHelper('get', '/api/stallion_breeding_estimates'),
  create: defineEndpointPathHelper('post', '/api/stallion_breeding_estimates'),
  new: defineEndpointPathHelper('get', '/api/stallion_breeding_estimates/new'),
  edit: defineEndpointPathHelper('get', '/api/stallion_breeding_estimates/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/stallion_breeding_estimates/:id'),
  update: defineEndpointPathHelper('patch', '/api/stallion_breeding_estimates/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/stallion_breeding_estimates/:id'),
}

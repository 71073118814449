import { useLocation, useNavigation, useResolvedPath } from 'react-router-dom'
import type { To } from 'react-router-dom'

export interface UseNavigationStatesProps {
  to: To
  end?: boolean
}

/**
 * Given a resolved `To`, produces the corresponding navigation states
 * of `isActive` and `isPending` like is done by the built-in React Router
 * `NavLink` compononent
 */
export const useNavigationStates = ({ to, end = false }: UseNavigationStatesProps) => {
  const location = useLocation()
  const navigation = useNavigation()

  const path = useResolvedPath(to)

  const toPathname = path.pathname
  const locationPathname = location.pathname
  const nextLocationPathname = navigation.location?.pathname

  // If the `to` has a trailing slash, look at that exact spot.  Otherwise,
  // we're looking for a slash _after_ what's in `to`.  For example:
  //
  // <NavLink to="/users"> and <NavLink to="/users/">
  // both want to look for a / at index 6 to match URL `/users/matt`
  const endSlashPosition =
    toPathname !== '/' && toPathname.endsWith('/') ? toPathname.length - 1 : toPathname.length

  const isActive =
    locationPathname === toPathname ||
    (!end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(endSlashPosition) === '/')

  const isPending =
    navigation.state === 'loading' &&
    nextLocationPathname != null &&
    (nextLocationPathname === toPathname ||
      (!end &&
        nextLocationPathname.startsWith(toPathname) &&
        nextLocationPathname.charAt(endSlashPosition) === '/'))

  return {
    isActive,
    isPending,
  }
}

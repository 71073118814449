// JsFromRoutes CacheKey ad013381cbaba4e9a74d192a0dbef32b
//
// DO NOT MODIFY: This file was automatically generated by custom JsFromRoutes.

import { defineEndpointPathHelper } from '@/libs/endpoint/endpoint-path-helper'

export const projectedPurchasesApi = {
  index: defineEndpointPathHelper('get', '/api/projected_purchases'),
  create: defineEndpointPathHelper('post', '/api/projected_purchases'),
  new: defineEndpointPathHelper('get', '/api/projected_purchases/new'),
  edit: defineEndpointPathHelper('get', '/api/projected_purchases/:id/edit'),
  show: defineEndpointPathHelper('get', '/api/projected_purchases/:id'),
  update: defineEndpointPathHelper('patch', '/api/projected_purchases/:id'),
  destroy: defineEndpointPathHelper('delete', '/api/projected_purchases/:id'),
}
